import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';
import Alert from '../Alert/Alert';
import './ListaWork.css';

import completo from '../../img/completo.png';
import incompleto from '../../img/incompleto.png';
import estrella from '../../img/estrella.png';
import flecha2 from '../../img/volverRed.png';

const Workouts = (()=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState([]);
	let hoy = new Date();
	hoy = hoy.getFullYear() + "-" + (hoy.getMonth() < 9 ? "0"+(hoy.getMonth()+1) : hoy.getMonth()+1) + "-" + (hoy.getDate() < 10 ? "0"+hoy.getDate() : hoy.getDate());
	useEffect(()=>{
		getData();
	}, [])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Workout/getAll.php"+(id?"?id="+id+"&token="+token : "?token="+token))
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json)
			return json;
		});
		setDatos(data["lista"] || []);
	}
	return(
		<>
		<div className='workouts workoutsLista'>
			{datos.length == 0 &&
				<div className="lvacio">Aún no has agregado resultados</div>
			}
			{datos.length!=0 &&
				<>
					<div className="contWorkouts contWorkoutsLista">
					{
						Object.keys(datos).map((mes, i)=>{
							console.log(datos, datos[mes])
							return(
								<>
									<div className='mesLista'>{mes}</div>
									{datos[mes].map(workout=>{
										return(<Workout workout={workout} datos={datos} setDatos={setDatos}  key={workout.id_workout} activo={true}/>);
									})
									}
								</>
							);
						})
					}
					</div>
				</>
			}
		</div>
		</>
	);
})

const Workout = ({workout, setModal, datos, setDatos, activo})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [completado, setCompletado] = useState(workout.completado=="true");
	const [score, setScore] = useState(workout.score || 0);
	const [editar, setEditar] = useState(workout.id_workout==0);
	const [alert, setAlert] = useState({ver:false, mensaje:"", funcion: null, confirm:true});
	const f = new Date(workout.year, workout.mes-1,workout.dia);
	const fecha = (workout.dia < 10 ? "0"+workout.dia : workout.dia)+"-"+(workout.mes < 10 ? "0"+workout.mes : workout.mes)+"-"+workout.year;
	const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
	const dia = f.getDay();
	const nombredia = dias[dia];
	function cambiaScore(score){
		console.log(workout.id);
		fetch("/cursos/clienteAPI/Workout/setScore.php?token="+token+"&id="+workout.id_workout+"&score="+score)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			if(json["status"]){
				setScore(score);
			}
		})
	}
	function cambiaEstado(){
		console.log(workout.id);
		fetch("/cursos/clienteAPI/Workout/setEstado.php?token="+token+"&id="+workout.id_workout+"&completado="+(!completado?"true" : "false"))
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			if(json["status"]){
				setCompletado(!completado);
			}
		})
	}
	return (
		<>
		{alert.ver &&
			<Alert alert={alert} setAlert={setAlert}/>
		}
		<div className="workout workoutLista">
			<div className="divsEstilo">
				<span className='botbotText'>
					<span className='sig'><Link to={'/perfil/workouts/'+fecha}>{nombredia + " " + workout.dia}</Link></span>
					<span><img src={flecha2}/></span>
				</span>
				<div className="descripcion">
					<span>Nombre</span>
					<span>{workout.nombre}</span>
				</div>
				<div className='auxLista'>
					<div className="descripcion">
						<span>Tipo</span>
						<span>{workout.tipo}</span>
					</div>
					<div className="descripcion">
						<span>Tipo de Score</span>
						<span>{workout.tipoS}</span>
					</div>
				</div>
				<div className="descripcion">
					<span>Descripción</span>
					<span>{workout.descripcion}</span>
				</div>
			</div>
			<div className='estadoW' onClick={cambiaEstado}>
				{completado &&
					<div className='completoW'>Completado</div>
				}
				{!completado &&
					<div className='incompletoW'>Completar</div>
				}
			</div>
			{/*completado && workout.tipoS == "Estrellas" &&
				<div>
					<div>Score</div>
					<div className='estrellas'>
						{[...Array(5)].map((x,i)=>{
							return(
								<div className={'estrella'+ (i < score ? ' activa':'')} onClick={()=>{
									cambiaScore(i+1);
								}}>
									<img src={estrella}/>
								</div>
							)
						})}
					</div>
				</div>
			*/}
		</div>
		</>
	);
}

export default Workouts;
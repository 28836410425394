import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyAAL2xcSAX66ObALoYoEKQLhcxR-hUTlBE",
    authDomain: "crossfit-d01cf.firebaseapp.com",
    projectId: "crossfit-d01cf",
    storageBucket: "crossfit-d01cf.appspot.com",
    messagingSenderId: "516496796632",
    appId: "1:516496796632:web:0507c86733fa6f984860ad"
};
// cpC5InSrdfTu6jVhd5SkE2:APA91bF_iowPwkv7OIw2ENtV_iUuDUCkH2_Y9GLiVxOOtnCNLa8WmDc8hzCWxyK8MLDBcecl5d3VXYXzNylX6IyJaRAy9FmQSCJB0kYw5PqZbwm430VgfzkdBtQ7nKy5JpOIx6mt3dOR 
const app = initializeApp(firebaseConfig);
var messaging;
try{
  messaging = getMessaging(app);
}
catch(err){
  console.error('Failed to initialize Firebase Messaging', err);
}

const getTokenS = () => {
  const tokenString = localStorage.getItem('token');
  try{
    const userToken = JSON.parse(tokenString);
    return userToken?.token || ""
  }
  catch(e){
    return "";
  }
};
function suscribir(auto){
  if(!messaging)
    return false;
  getToken(messaging, { vapidKey: 'BAStjA8egcBdxUDsM2MP7WACkneAlvh0rSgRC_6EEKCADZ-5aTZgnt5E5aRyPSjbRpKkq9ZAY5Ys8d_m4RDYL2c' }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
      console.log(currentToken)
      const token = getTokenS();
      fetch("/cursos/suscribe.php?userToken="+currentToken+"&force="+(auto ? "false" : "true")+"&token="+token)
      .then(response=>response.json())
      .then(json=>{
      })
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}

async function deviceToken(){
  if(!messaging)
    return false;
  return await getToken(messaging, { vapidKey: 'BAStjA8egcBdxUDsM2MP7WACkneAlvh0rSgRC_6EEKCADZ-5aTZgnt5E5aRyPSjbRpKkq9ZAY5Ys8d_m4RDYL2c' }).then((currentToken) => {
   return currentToken
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}

function unsubscribe(){
  if(!messaging)
    return false;
  getToken(messaging, { vapidKey: 'BAStjA8egcBdxUDsM2MP7WACkneAlvh0rSgRC_6EEKCADZ-5aTZgnt5E5aRyPSjbRpKkq9ZAY5Ys8d_m4RDYL2c' }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
      console.log(currentToken)
      const token = getTokenS();
      fetch("/cursos/unsubscribe.php?userToken="+currentToken+"&token="+token)
      .then(response=>response.json())
      .then(json=>{
      })
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}

const onMessageListener = () =>
  new Promise((resolve) => {
    if(!messaging)
      return false;
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

function requestPermission(input) {
    console.log('Requesting permission...');
    if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream){return false}
    if (!'PushManager' in window){return false;}
    if (!'serviceWorker' in navigator){return false;}
    if (!'Notification' in window){return false;}
    Notification.requestPermission().then((permission) => {
      console.log(permission)
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        suscribir(!input);
      }
      else{
        input && (input.checked=false)
      }
    });
}
export {requestPermission, unsubscribe, onMessageListener, deviceToken};
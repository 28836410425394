import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '../../useToken';
import Alert from '../Alert/Alert';
import { Workout } from '../Workouts/Workouts';
import completo from '../../img/completo.png';
import incompleto from '../../img/incompleto.png';
import estrella from '../../img/estrella.png';


const WorkoutsB = (()=>{
	const {token, setToken} = useToken();
	const [datos, setDatos] = useState([]);
	const [buscar, setBuscar] = useState('');
	const [modal, setModal] = useState(false);
	const navigate = useNavigate();
	const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
	const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
	useEffect(()=>{
		getData();
	}, [buscar])
	useEffect(()=>{
		if(modal){
			document.body.addEventListener("keydown", cerrar)
		}
		else{
			document.body.removeEventListener("keydown", cerrar)
		}
	}, [modal])

	function cerrar(e){
		if(e.code=="Escape"){
			if(document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA"){
				setModal(false);
			}
		}
	}
	async function getData(){
		if(buscar!=""){
			const data = await fetch("/cursos/clienteAPI/Workout/buscar.php?token="+token+"&buscar="+buscar)
			.then(response=> response.json())
			.then(json=>{
				if(json["errAuth"]){
					setToken({})
					return false;
				}
				return json;
			});
			console.log(data["lista"]);
			setDatos(data["lista"] || []);
			console.log(datos);
		}
		else{
			if(datos.length!=0)
				setDatos([]);
		}
	}
	return(
		<>
		<div className='fechaCompleta'>{}</div>
		<div className='descuentos'>
			{modal &&
				<div className="modal modalWorkout" onClick={(e)=>{
					if(e.target===e.currentTarget){
						setModal(false);
					}
				}}>
					<div>
						<Workout workout={{
							id_workout : 0,
							nombre : "",
							descripcion : "",
							tipo : "Lifestyle",
							tipoS : "",
						}}
						setModal={setModal}
						datos={datos}
						setDatos={setDatos}
						/>
					</div>
				</div>
			}
			<div className='buscador top'>
				<input type='text' placeholder='Buscar...' onChange={(e)=>{
					setBuscar(e.currentTarget.value);
				}}/>
				<span className='botones'>
					<span className='cancel' onClick={()=>{
						navigate('/perfil/workouts');
					}}>Cancelar</span>
				</span>
			</div>
			{datos.length == 0 &&
				<div className="lvacio">{buscar=="" ? "Aquí aparecerán los resultados":"No hay resutaldos que coincidan con la búsqueda"}</div>
			}
			{datos.length!=0 &&
				<>
					<div className="contWorkouts">
					{
						datos.map(workout=>{
							return(
								<Workout workout={workout} setModal={setModal} datos={datos} setDatos={setDatos}  key={workout.id_workout} activo={true}/>
							);
						})
					}
					</div>
				</>
			}
		</div>
		<div className='nuevo' onClick={()=>{
			setModal(true);
		}}>
			+
		</div>
		</>
	);
})

export default WorkoutsB;
import React, { useState, useEffect, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, useParams, useNavigate, Outlet } from 'react-router-dom';
import CursoNav from '../../components/CursoNav/CursoNav';
import './cursos.css';


import volver from '../../img/volver.png';

const Curso = (({modo})=>{
	console.log(modo);
	const {id} = useParams();
	const navigate = useNavigate();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [curso, setCurso] = useState([]);
	const [inscrito, setInscrito] = useState(false);
	useEffect(()=>{
		getData();
	}, [token])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Curso/get.php?id_curso="+id+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		if(data["id_curso"]){
			setCurso(data || {});
			setInscrito(data.inscrito!=0);
		}
		else{
			navigate('/cursos/');
		}
	}
	return(
	<>
		{token!="" &&
			<Link className='atras' to='/cursos'><img src={volver} />Todos los cursos</Link>
		}
		<div className='contCurso'>
			<div className='datosCurso'>
				<div className='info'>
					<div className='divImg'>
						<img src={"/cursos/img/"+curso.ruta}/>
					</div>
					<div>
						<div>
							<span>{curso.nombre}</span>
						</div>
						<div>
							<span>Descripción</span>
							<span>{curso.descripcion}</span>
						</div>
					</div>
					<div>
						{!inscrito &&
						<>
							{curso.precio != 0 &&
								<div>
									<span>¡Inscribete ya!, por solo:</span>
									{curso.id_descuento && curso.codigo =="" &&
										<span className="spanPromo">
											<span className="antes">Antes:<span className='tachar'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio)}</span></span>
											<span className='conDescuento'>{curso.tipo=="Porcentaje" ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio - (curso.precio*curso.descuento/100)) : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio - curso.descuento)}</span>
											<span className='ahorro'>Ahorra {curso.tipo=="Porcentaje" ? curso.descuento+"%"+" ("+(new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio*curso.descuento/100))+")" : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.descuento)}</span>
										</span>
									}
									{!curso.id_descuento &&
										<span className="sinDescuento">{curso.precio ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio) : "---"}</span>
									}
								</div>
							}
							<div className="botones">
								{token!="" &&
									<div className="sig" onClick={()=>{
										if(curso.precio != 0)
											navigate('/cursos/'+id+"/inscribir");
										else{
											fetch("/cursos/clienteAPI/Suscribir/suscribir.php?id_curso="+id+"&token="+token)
											.then(response=> response.json())
											.then(json=>{
												if(json["errAuth"]){
													setToken({})
													return false;
												}
												if(json["status"]){
													setInscrito(true);
												}
											});
										}
									}}>
									{curso.precio != 0 &&
										<>
										Inscribirse ({curso.precio ? curso.tipo=="Porcentaje" && curso.codigo=="" ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio - (curso.precio*curso.descuento/100)) : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(curso.precio - curso.descuento) : ""})
										</>
									}
									{curso.precio == 0 &&
										<>Inscribirse Gratis</>
									}
									</div>
								}
								{!token &&
									<Link className="sig" to='/login'>
										Inicia sesión
									</Link>
								}
							</div>
						</>
						}
					</div>
				</div>
				<div className='stats'>
					<div>
						<span>Videos</span>
						<span>{curso.numVids || 0}</span>
					</div>
					<div>
						<span>Archivos</span>
						<span>{curso.numFiles || 0}</span>
					</div>
					{/*<div>
						<span>Quizz</span>
						<span>{curso.numCuest || 0}</span>
					</div>*/}
				</div>
			</div>
		</div>
		<CursoNav modo={modo}/>
		<Outlet context={[inscrito, setInscrito]}/>
	</>
	);
})
export default Curso;
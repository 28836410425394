import React, { useState, useEffect, useRef } from 'react';
import useToken from '../../useToken';
import { v4 as uuidv4 } from 'uuid';
import { Link, Outlet, useNavigate, useParams, useOutletContext } from 'react-router-dom';
import './Preview.css';

import Guardar from '../../img/guardar.png';
import Cancelar from '../../img/cancelar.png';
import antes from '../../img/ant.png';
import despues from '../../img/sig.png';
import like from '../../img/like.png';
import liked from '../../img/liked.png';
import saveWhite from '../../img/saveWhite.png';
import saved from '../../img/saved.png';
import vistas from '../../img/vistaV.png';
import compartir from '../../img/compartir.png';

const Preview = (()=>{
	const {token, setToken} = useToken();
    const [datos, setDatos, categoria, setPage, more] = useOutletContext();
    const navigate = useNavigate();
    const [editar, setEditar] = useState(false)
    const [flechas, setFlechas] = useState({});
    const [mas, setMas] = useState(false);
    const activo = useRef();
    const swiper = useRef();
    const video1 = useRef();
    const video2 = useRef();
    const video3 = useRef();
    const [categorias, setCategorias] = useState([]);
    const [megusta, setMegusta] = useState(false);
    const [guardado, setGuardado] = useState(false);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const [short, setShort] = useState({
        id_short: uuidv4(),
        id_archivo: 0,
        nombre: "",
        descripcion: "",
        filtro: "",
        texto: "",
        estado: "Público",
        categorias: [],
    });
    const [imagen, setImagen] = useState({});
    const {id} = useParams();
    useEffect(()=>{
        getData();
        document.body.onkeyup = cambiarKey;
    }, [id])
    useEffect(()=>{
        let indice = datos.findIndex(x=> x.id_short==short.id_short);
        let aux = {};
        if(indice==0){
            aux.antes = "";
        }
        else{
            aux.antes = datos[indice-1];
        }

        if(indice!=0 && indice == datos.length-2 && more){
            setPage(page=>page+1);
        }
        if(indice == datos.length-1){
            aux.despues = "";
        }
        else{
            console.log(datos[indice+1])
            aux.despues = datos[indice+1];
        }
        setFlechas(aux);
    }, [datos])
    useEffect(()=>{
        document.body.onkeyup = cambiarKey;
        activo.current && activo.current.scrollIntoView();
    }, [flechas])

    async function getData(){
        if(id){
            const data = await fetch("/cursos/clienteAPI/Shorts/get.php?token="+token+"&id="+id)
            .then(response=> response.json())
            .then(json=>{
                if(json["errAuth"]){
                    setToken({})
                    return false;
                }
                return json;
            });
            setShort(data || []);
            setMas(data.descripcion.length < 30);
            setMegusta(data.liked == "true");
            setGuardado(data.saved == "true");
            setImagen({id_archivo:data.id_archivo, ruta: data.ruta})
            setCategorias(data.categorias)
            datos.some(x=>{
                if(x.id_short==data.id_short){
                    x.vistas=data.vistas;
                    return true;
                }
            })
            setDatos([...datos]);
            let indice = datos.findIndex(x=> x.id_short==data.id_short);
            let aux = {};
            if(indice==0){
                aux.antes = "";
            }
            else{
                aux.antes = datos[indice-1];
            }

            if(indice == datos.length-1){
                aux.despues = "";
            }
            else{
                aux.despues = datos[indice+1];
            }
            setFlechas(aux);
        }
    }

    function cambiarKey(e){
        if(e.target.tagName.toLowerCase() != "input" && e.target.tagName.toLowerCase() != "textarea"){
            if(e.code=="ArrowDown" || e.code=="ArrowUp"){
                cambiar(e.code=="ArrowDown")
            }
            else if(e.code=="Escape"){
                document.body.onkeyup = undefined;
                navigate(-1)
            }
        }
    }

    function cambiar(sig){
        let url = flechas.antes.id_short
        if(sig)
            url = flechas.despues.id_short;
        if(url && url!=""){
            navigate(categoria ? '/shorts/categoria/'+categoria+'/'+url:"/shorts/"+url, {replace:true})
        }
    }
    var inicial = 0;
    var final = 0;
    function mouseDown(e){
        e.preventDefault();
        inicial = e.clientY;
    }
    function touchStart(e){
        inicial = e.touches[0].clientY;
    }
    function mouseMove(e){
        if(inicial==0)
            return false;
        final = e.clientY;
        let pantalla = activo.current.previousElementSibling ? activo.current.offsetHeight : 0;
        swiper.current.scrollTop = pantalla - (final - inicial);
    }
    function touchMove(e){
        if(inicial==0)
            return false;
        final = e.touches[0].clientY;
        let pantalla = activo.current.previousElementSibling ? activo.current.offsetHeight : 0;
        swiper.current.scrollTop = pantalla - (final - inicial);
    }
    function mouseUp(e){
        if(inicial == 0)
            return false;
        let pantalla = activo.current.previousElementSibling ? activo.current.offsetHeight : 0;
        final = e.clientY;
        if(Math.abs(final - inicial) > activo.current.offsetHeight/3){
            if(final - inicial > 0)
                cambiar(false);
            else
                cambiar(true);
        }
        else{
            swiper.current.scrollTop = pantalla;
        }
        inicial = 0;
    }
    function touchEnd(e){
        if(inicial == 0 || final==0)
            return false;
        let pantalla = activo.current.previousElementSibling ? activo.current.offsetHeight : 0;
        if(Math.abs(final - inicial) > activo.current.offsetHeight/3){
            if(final - inicial > 0)
                cambiar(false);
            else
                cambiar(true);
        }
        else{
            swiper.current.scrollTop = pantalla;
        }
        inicial = 0;
    }
    function meGusta(e){
        e.stopPropagation();
        fetch("/cursos/clienteAPI/Shorts/like.php?id_short="+short.id_short+"&like="+!megusta+"&token="+token)
        .then(response=> response.json())
        .then(json=>{
            if(json["errAuth"]){
                setToken({})
                return false;
            }
            short.liked = megusta;
            setMegusta(!megusta)
            datos.some(x=>{
                if(x.id_short==short.id_short){
                    x.liked = !short.liked+"";
                    return true;
                }
            })
            setDatos([...datos]);
        });
    }
    function guardar(e){
        e.stopPropagation();
        fetch("/cursos/clienteAPI/Shorts/save.php?id_short="+short.id_short+"&save="+!guardado+"&token="+token)
        .then(response=> response.json())
        .then(json=>{
            if(json["errAuth"]){
                setToken({})
                return false;
            }
            short.saved = guardado;
            setGuardado(!guardado)
            datos.some(x=>{
                if(x.id_short==short.id_short){
                    x.saved = !short.saved+"";
                    return true;
                }
            })
            setDatos([...datos]);
        });
    }

    function cortar(desc){
        if(!desc)
            return false;
        let cortado = "";
        let palabras = desc.split(" ");
        let suma = 0;
        let max  = 30;//maximo 20 letras
        palabras.some((palabra, i)=>{
            suma+=palabra.length;
            if(suma > max){
                cortado+=palabra+"...";
                return true;
            }
            else{
                cortado+=palabra+" ";
            }
        })
        return cortado;
    }

    function comparte(e){
        if(navigator.share){
            navigator.share({
                title: short.nombre,
                text: short.descripcion,
                url: '/app/shorts/'+short.id_short,
            })
        }
    }
    function playpause() {
        const actualShort = document.querySelector("#pruebaD");
        const botonpp = document.querySelector(".botonDon");

        botonpp.style.display = "inherit";
        botonpp.classList.remove("android");
        var contador;
        if (actualShort.paused) {
            actualShort.play();
            botonpp.classList.remove("play");
        } 
        else {
            actualShort.pause();
            botonpp.className += " play";
        }
        contador = setTimeout(ocultarT, 3000);
    }
    function ocultarT(){
        document.querySelector(".botonDon").style.display = "none";
    }
	return(
		<>
            {imagen.id_archivo &&
                <div className='contPreview'>
                    <div className='prevVideo'>
                        <div className='swiper' ref={swiper}>
                        {flechas.antes &&
                            <div className='contShort'>
                                <video 
                                /*ref={video1}*/
                                src={flechas.antes.ruta.substring(0,5) == "https" ? flechas.antes.ruta : '/cursos/video/'+flechas.antes.ruta+"#t=0.001"}
                                className={"cargandoVideo"} 
                                controls
                                playsInline
                                onLoadedData={(e)=>{
                                    console.log("loaded")
                                    e.target.classList.remove("cargandoVideo")
                                    e.target.removeAttribute('controls')
                                }}
                                preload='metadata'></video>
                            </div>
                        }
                        <div className='contShort' ref={activo}
                            onMouseDown={mouseDown}
                            onMouseUp={mouseUp}
                            onMouseMove={mouseMove}
                            onMouseEnter={mouseUp}
                            onMouseLeave={mouseUp}
                            onTouchStart={touchStart}
                            onTouchMove={touchMove}
                            onTouchEnd={touchEnd}
                            /*key={imagen?.ruta || "cargando"}*/
                        >
                            <video 
                                /*ref={video2}*/
                                id="pruebaD"
                                src={imagen.ruta.substring(0,5) == "https" ? imagen.ruta : '/cursos/video/'+imagen.ruta+"#t=0.001"}
                                className={"cargandoVideo fm-video video-js"} 
                                
                                onLoadedData={(e)=>{
                                    console.log("loaded")
                                    e.target.classList.remove("cargandoVideo")
                                }}
                                autoPlay={true}
                                preload='auto' 
                                loop
                                playsInline
                                onClick={()=>{
                                    playpause();
                                }}>
                            </video>
                            <span id="bpp" className={"botonDon " + (iOS ? "play" : "android")} onClick={()=>{
                                    playpause();
                                }}
                            ></span>
                        </div>
                        {flechas.despues &&
                            <div className='contShort'>
                                <video 
                                /*ref={video3}*/
                                src={flechas.despues.ruta.substring(0,5) == "https" ? flechas.despues.ruta : '/cursos/video/'+flechas.despues.ruta+"#t=0.001"}
                                className={"cargandoVideo"} 
                                controls
                                playsInline
                                onLoadedData={(e)=>{
                                    console.log("loaded")
                                    e.target.classList.remove("cargandoVideo")
                                    e.target.removeAttribute('controls')
                                }}
                                preload='metadata'></video>
                            </div>
                        }
                        </div>
                        
                        <div className='cerrarVideo' onClick={()=>{
                            document.body.onkeyup = undefined;
                            navigate(-1);
                        }}>x</div>
                        <div className='flechas'>
                            <div className='anterior' onClick={()=>{
                                cambiar()
                            }}>
                                {flechas.antes &&
                                    <img src={antes}/>
                                }
                            </div>
                            <div className='siguiente' onClick={()=>{
                                cambiar(true)
                            }}>
                                {flechas.despues &&
                                    <img src={despues}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='prevDesc'>
                        <div className='tituloShort'>{short.nombre}</div>
                        <div className="descript">
                            <span>
                                {mas ? short.descripcion : cortar(short.descripcion)}
                            </span>
                            {short.descripcion.length > 30 &&
                                <b className='mostrarMas' onClick={()=>{
                                    setMas(!mas);
                                }}>{mas ? "Mostrar menos" : "Mostrar más"}</b>
                            }
                        </div>
                        <div className="catAsig">
                            {mas &&
                                <Categorias categorias={categorias} editar={editar}/>
                            }
                        </div>
                        <div className='interacciones'>
                            <div className='icono vis'>
                                <div className='contMini'>
                                    <img src={vistas}/>
                                </div>
                                <div className="contMinia">
                                    <span>{short.vistas}</span>
                                    <span className='fijo'> Vistas</span>
                                </div>
                            </div>
                            <div className='icono' onClick={comparte}>
                                <div className='contMini'>
                                    <img src={compartir}/>
                                </div>
                                <div>Compartir</div>
                            </div>
                            {token!="" &&
                            <>
                                <div className='like' onClick={meGusta}>
                                    <div className={megusta ? 'contMini likedD' : 'contMini grisS'}>
                                        <img src={megusta ? liked : like}/>
                                    </div>
                                    <div className={megusta ? 'liked' : 'gris'}>Me gusta</div>
                                </div>
                                <div className='save' onClick={guardar}>
                                    <div className={guardado ? 'contMini savedD' : 'contMini grisS'}>
                                        <img src={guardado ? saved : saveWhite}/>
                                    </div>
                                    <div className={guardado ? 'saved' : 'gris'}>Guardar</div>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            }
		</>
	);
})

const Categorias = ({categorias, setCategorias, editar})=>{
    const {token, setToken} = useToken();
    return(
        <div>
            <div className='asignadas'>
                {categorias.map(categoria=>{
                    return(
                        <Link onClick={()=>{
                            document.body.onkeyup = undefined;}} 
                            to={'/shorts/categoria/'+categoria.categoria} key={uuidv4()}>{categoria.categoria}</Link>
                    );
                })

                }
            </div>
        </div>
    );
}

export default Preview;
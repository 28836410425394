import React, { useState, useEffect, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, useParams } from 'react-router-dom';
import Alert from '../Alert/Alert';
import './Mensajes.css';

const Mensajes = (()=>{
	const {id = 0} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [mostrar, setMostrar] = useState(false);
	const [imagen, setImagen] = useState({id_archivo:0, ruta:''});
	const [mensajes, setMensajes] = useState([]);
	const [buscar, setBuscar] = useState('');
	const [cargando, setCargando] = useState(false);
	const [alert, setAlert] = useState({ver:false, mensaje:"", funcion: null, confirm:false});
	const nuevoMensaje = useRef(null);
	useEffect(()=>{
		getData();
	}, [])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Mensajes/getAll.php?id="+id+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setMensajes(data || []);
	}

	return(
		<>
		{alert.ver &&
			<Alert alert={alert} setAlert={setAlert}/>
		}
		<div className='buscador'>
			<input 
			placeholder='Busca...'
			onChange={(e)=>{
				setBuscar(e.target.value);
			}}/>
		</div>
		<div className="mensaTit">Mensajes de tu Instructor</div>
		<div className='contMensajes mensajesC'>
			{
				mensajes.map(mensaje=>{
					if(buscar=='' || mensaje.mensaje.toLowerCase().includes(buscar.toLowerCase()))
					return(
						<Mensaje mensaje={mensaje} setMensajes={setMensajes} mensajes={mensajes} key={mensaje.id_mensaje}/>
					)
				})
			}
		</div>
		</>
	);
})

const Mensaje = ({mensaje, setMensajes, mensajes})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	return (
		<>
		<div className='mensaje'>
			<span className='fecha'>
				{mensaje.fecha}
			</span>
			<span className='descMensaje'>
				{mensaje.id_archivo ? 
					<div className='divImg'>
						<img src={'/cursos/clienteAPI/img/'+mensaje.ruta}/>
					</div>
					:
					null
				}
				<div>{mensaje.mensaje}</div>
			</span>
		</div>
		</>
	);
}

const Respuesta = ({respuesta})=>{
	return(
		<div className='respuesta'>
			<div>
				{respuesta.respuesta}
			</div>
			<div>
				{respuesta.fecha}
			</div>
		</div>
	);
}

export default Mensajes;
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';

import Ganadas from '../Ganadas/Ganadas';
import Comentarios from '../Comentarios/Comentarios';

import './Video.css';

import cuestionario from '../../img/cuestionario.png';
import like from '../../img/like.png';
import liked from '../../img/liked.png';
import save from '../../img/save.png';
import saved from '../../img/saved.png';
import teoria from '../../img/teoria.png';
import video from '../../img/video.png';
import volver from '../../img/volver.png';

const Video = ()=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState({});
	const [progreso, setProgreso] = useState(0);
	const [duracion, setDuracion] = useState(0);
	const [megusta, setMegusta] = useState(false);
	const [guardado, setGuardado] = useState(false);
	const [insignias, setInsignias] = useState([]);
	const videoActual = useRef();

	useEffect(()=>{
		getData();
	}, [])
	useEffect(()=>{
		if(videoActual.current){
			videoActual.current.play();
		}
	}, [videoActual])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Videos/get.php?id_elemento="+id+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setDatos(data || [])
		setMegusta(data.liked == "true");
		setGuardado(data.saved == "true");
	}

	useEffect(()=>{
		if(duracion!=0 && progreso!=0){
		let porcentaje = Math.round(progreso*100/duracion);
		fetch("/cursos/clienteAPI/Videos/setProgreso.php?id_video="+datos.id_elemento+"&progreso="+porcentaje+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			setInsignias(json["insignias"]||[]);
		});	
		}
	}, [progreso])
	function meGusta(e){
		e.stopPropagation();
		fetch("/cursos/clienteAPI/Videos/like.php?id_video="+datos.id_elemento+"&like="+!megusta+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			datos.liked = megusta;
			setMegusta(!megusta)
		});
	}
	function guardar(e){
		e.stopPropagation();
		fetch("/cursos/clienteAPI/Videos/save.php?id_elemento="+datos.id_elemento+"&tipo="+datos.tipo+"&save="+!guardado+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			datos.saved = guardado;
			setGuardado(!guardado)
		});
	}
	const navigate = useNavigate();
	const tiposImg = {
		"video": video,
		"cuestionario": cuestionario,
		"multi": teoria,
	}
	function handleProgreso(e, finished){
		if(finished){
			return setProgreso(e.target.duration);
		}
		else if(e.target.currentTime - progreso > 30){
			setProgreso(e.target.currentTime);
		}
	}

	if(datos.inscrito)
		return (
			<>
			<Link className="atras" to={'/cursos/'+datos.id_curso+'/secciones/'+datos.id_seccion}><img src={volver} />Regresar</Link>
			<Ganadas insignias={insignias} setInsignias={setInsignias}/>
			<div className='video progresoElem completo'>
			{datos.inscrito && datos.ruta && datos.tipo == "video" &&
				<div className={'image'+(datos.completado=='true' ? " completado" : "")}>
					<div className='relative'>
						<video 
							ref={videoActual}
							controls
							controlsList="nodownload"
							preload='auto'
							playsInline
							poster={datos.rutaMini ? "/cursos/img/"+datos.rutaMini : tiposImg[datos.tipo]}
							onLoadedMetadata={(e)=>{
								setDuracion(e.target.duration);
								e.target.currentTime = datos.progreso*e.target.duration/100;
							}}
							onPause={handleProgreso}
							onTimeUpdate={handleProgreso}
							onEnded={(e)=>handleProgreso(e, true)}
							onContextMenu={(e)=>{
								e.preventDefault();
							}
							}
							src={'/cursos/video/'+datos.ruta}>
						</video>
						<div className='megusta' onClick={meGusta}>
							<img src={megusta ? liked : like}/>
						</div>
						<div className='guardar' onClick={guardar}>
							<img src={guardado ? saved : save}/>
						</div>
					</div>
				</div>
			}
			{!datos.inscrito || !(datos.ruta && datos.tipo=="video") && 
				<div className={'image'+(datos.completado=="true" ? " completado" : "")}>
					<img src={tiposImg[datos.tipo]}/>
				</div>
			}
				<div className='descElem'>
					<div><b>{datos.nombre}</b></div>
					<div>{datos.descripcion}</div>
				</div>
			</div>
			<Comentarios id_video={id}/>
			</>
		);
	else if(datos.inscrito === false)
		return(
			<div className='forbidden'>
				{token != "" &&
					<>
						<div>Lo sentimos. No tienes acceso a este video</div>
						<div>Consigue acceso suscribiendote a este curso</div>
						<div className="botonsigLA"><Link to={'/cursos/'+datos.id_curso}>Ir al curso</Link></div>
					</>
				}
				{token == "" &&
					<>
						<div>Inicia sesión para poder acceder a los cuestionarios de tus cursos.</div>
						<div className='botones'>
							<Link className="sig" to='/login'>
								Iniciar sesión
							</Link>
						</div>
					</>
				}
			</div>
		);
}

export default Video;
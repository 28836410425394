import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';
import Alert from '../Alert/Alert';
import VerFile from '../VerFile/VerFile';
import VerTestimonio from '../VerTestimonio/VerTestimonio';
import './Testimonios.css';


const Testimonios = (()=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [multimedias, setMultimedias] = useState([]);
	const [buscar, setBuscar] = useState('');
	useEffect(()=>{
		getData();
	}, [])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Testimonio/getAll.php?token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			return json;
		});
		setMultimedias(data || {});
	}
	return(
		<>
			<div className="titBienvenida">Gente que como tú, quiso cambiar su vida</div>
			<div className="titBajo">Conoce las historias de nuestra familia</div>
			<div className='contMultimedias multiCliente'>
			{
			multimedias.map((multimedia, i)=>{
				multimedia.orden = i+1;

				if(buscar=='' || multimedia.nombre.toLowerCase().includes(buscar.toLowerCase()))
				return (<Multimedia multimedia={multimedia} key={multimedia.id_multimedia} setMultimedias={setMultimedias} multimedias={multimedias}/>);
			})
			}
			{multimedias.length == 0 &&
				<div className="lvacio">Aún no han agregado ningún video.</div>
			}
			</div>
		</>
	);
})

const Multimedia = (({multimedia, setNuevo, setMultimedias, multimedias})=>{
	const {id} = useParams();
	const [editar, setEditar] = useState(multimedia.id_multimedia==0);
	const [alert, setAlert] = useState({ver:false, mensaje:"", funcion: null, confirm:true});
	const [activo, setActivo] = useState(0);

	useEffect(()=>{
		if(alert.ver){
			document.body.addEventListener("keydown", cerrar)
		}
		else{
			document.body.removeEventListener("keydown", cerrar)
		}
	}, [alert.ver])

	function cerrar(e){
		if(e.code=="Escape"){
			if(document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA"){
				setAlert({...alert, ver:false});
			}
		}
	}
	return(<>
		{alert.ver &&
			<Alert alert={alert} setAlert={setAlert}/>
		}
		<div className='infoMultimedia'> 
			<div className='descMultimedia descTest'>
				<VerFile imagen={{ruta:multimedia.archivos[activo]?.ruta}} tipo={"multi"}/>
				{multimedia.archivos.length>1 &&
				<div className='contMultiple'>
					{multimedia.archivos.map((archivo, i)=>{
						return(
							<VerTestimonio setActivo={setActivo} indice={i} activo={i==activo} imagen={{ruta:archivo.ruta}} tipo={"multi"} noclick={true}/>
							)
						})
					}
				</div>
				}
				<div className='datosMultimedia divsEstilo'>
					<div>
						{editar &&
							<input defaultValue={multimedia.nombre} onChange={(e)=>{
								multimedia.nombre = e.currentTarget.value
							}}/>
						}
						{!editar &&
							<span>{multimedia.nombre}</span>

						}
					</div>
					<div className='descripcion'>
						{editar &&
							<textarea defaultValue={multimedia.descripcion} onChange={(e)=>{
								multimedia.descripcion = e.currentTarget.value
							}}></textarea>
						}
						{!editar &&
							<span>{multimedia.descripcion}</span>

						}
					</div>
				</div>
			</div>
		</div>
	</>);
});

export default Testimonios;
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../../useToken';
import Alert from '../Alert/Alert';
import './Workouts.css';

import completo from '../../img/completo.png';
import incompleto from '../../img/incompleto.png';
import estrella from '../../img/estrella.png';


const Workouts = (()=>{
	const {fecha} = useParams();
	const {token, setToken} = useToken();
	const [datos, setDatos] = useState([]);
	const [buscar, setBuscar] = useState('');
	const [modal, setModal] = useState(false);
	const fs = fecha.split("-");
	const f = new Date(fs[2], fs[1]-1, fs[0]);
	const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
	const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
	const fechaCompleta = dias[f.getDay()]+", "+ f.getDate() + " de " + meses[f.getMonth()] + " de " + f.getFullYear();
	const nombre = useRef();
	const descripcion = useRef();
	const precio = useRef();
	useEffect(()=>{
		getData();
	}, [])
	useEffect(()=>{
		if(modal){
			document.body.addEventListener("keydown", cerrar)
		}
		else{
			document.body.removeEventListener("keydown", cerrar)
		}
	}, [modal])

	function cerrar(e){
		if(e.code=="Escape"){
			if(document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA"){
				setModal(false);
			}
		}
	}
	async function getData(){
		let date = fecha.split("-");
		const data = await fetch("/cursos/clienteAPI/Workout/getDay.php?token="+token+"&dia="+date[0]+"&mes="+date[1]+"&year="+date[2])
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		console.log(data["lista"]);
		setDatos(data["lista"] || []);
	}
	return(
		<>
		<div className='fechaCompleta'>{fechaCompleta}</div>
		<div className='descuentos'>
			{modal &&
				<div className="modal modalWorkout" onClick={(e)=>{
					if(e.target===e.currentTarget){
						setModal(false);
					}
				}}>
					<div>
						<Workout workout={{
							id_workout : 0,
							nombre : "",
							descripcion : "",
							tipo : "Lifestyle",
							tipoS : "",
						}}
						setModal={setModal}
						datos={datos}
						setDatos={setDatos}
						/>
					</div>
				</div>
			}
			{datos.length == 0 &&
				<div className="lvacio">Aún no has agregado resultados</div>
			}
			{datos.length!=0 &&
				<>
					<div className="contWorkouts">
					{
						datos.map(workout=>{
							if(buscar=='' || workout.nombre.toLowerCase().includes(buscar.toLowerCase()) || workout.codigo.toLowerCase().includes(buscar.toLowerCase()))
								return(
									<Workout workout={workout} setModal={setModal} datos={datos} setDatos={setDatos}  key={workout.id_workout} activo={true}/>
								);
						})
					}
					</div>
				</>
			}
		</div>
		<div className='nuevo' onClick={()=>{
			setModal(true);
		}}>
			+
		</div>
		</>
	);
})

const Workout = ({workout, setModal, datos, setDatos, activo})=>{
	const {fecha} = useParams();
	const {token, setToken} = useToken();
	const [completado, setCompletado] = useState(workout.completado=="true");
	const [radio, setRadio] = useState("Custom");
	const [tipo, setTipo] = useState(workout.tipo || "Lifestyle");
	const [tipoS, setTipoS] = useState(workout.tipo != "Workout" ? "Estrellas" : workout.tipoS ? workout.tipoS : "Tiempo");
	const [editar, setEditar] = useState(workout.id_workout==0);
	const [alert, setAlert] = useState({ver:false, mensaje:"", funcion: null, confirm:true});
	const [lista, setLista] = useState([]);
	const [scored, setScored] = useState(workout.score || 0);
	const nombre = useRef();
	const descripcion = useRef();
	const comentario = useRef();
	const score = useRef();

	useEffect(()=>{
		fetch("/cursos/clienteAPI/Workout/getLista.php?token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			setLista(json || []);
		});
	}, [])

	function cambiaScore(score){
		if(workout.id_workout==0){
			setScored(score)
			return false;
		}
		fetch("/cursos/clienteAPI/Workout/setScore.php?token="+token+"&id="+workout.id_workout+"&score="+score)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			if(json["status"]){
				setScored(score);
			}
		})
	}
	function cambiaEstado(){
		if(workout.id_workout==0){
			setCompletado(!completado);
			return false;
		}
		fetch("/cursos/clienteAPI/Workout/setEstado.php?token="+token+"&id="+workout.id_workout+"&completado="+(!completado?"true" : "false"))
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			if(json["status"]){
				setCompletado(!completado);
			}
		})
	}
	function guardar(){
		workout["nombre"] = nombre.current.value;
		workout["descripcion"] = descripcion.current.value;
		workout["comentario"] = comentario.current.value;
		workout["tipo"] = tipo;
		workout["tipoS"] = tipoS;
		workout["fecha"] = fecha.split("-").reverse().join("-");
		if(tipoS == "Estrellas"){
			workout["score"] = scored;
		}
		else{
			workout["score"] = score.current.value;
		}
		workout["completado"] = completado ? "true" : "false";
		let data = {...workout}
		fetch("/cursos/clienteAPI/Workout/set.php?token="+token, {
	      method: 'POST',
	      mode: 'cors',
	      headers: {
	        Accept: 'application/json',
	        'Content-Type': 'application/json',
	        'Access-Control-Allow-Origin':'*'
	      },
	      body: JSON.stringify(data)
	    })
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			if(json["status"]){
				if(workout.id_workout==0){
					data["id_workout"] = json["id_workout"];
					setDatos(datos=>[...datos, data]);
					setModal(false);
				}
				else{
					setDatos(datos=>
						[...datos]
					)
					setEditar(false);
				}
			}
		})
	}
	function cancelar(){
		if(workout.id_workout==0)
			setModal(false)
		else{
			setEditar(false);
		}
	}
	function borrar(){
		setAlert({ver:true, mensaje:"¿Estás seguro que deseas eliminar '"+workout.nombre+"'?", funcion:borrarC, confirm:true});
	}
	function borrarC(){
		
		fetch("/cursos/clienteAPI/Workout/borrar.php?id="+workout.id_workout+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			datos = [...datos].filter(x=>{
				return x.id_workout != workout.id_workout;
			})
			setDatos(datos);
		});
	}
	return (
		<>
		{alert.ver &&
			<Alert alert={alert} setAlert={setAlert}/>
		}
		<div className={editar && workout.id_workout!=0 ? "modal modalWorkout" : ""} onClick={(e)=>{
			if(e.target===e.currentTarget){
				setEditar(false);
			}
		}}>
			<div className="workout">
					<div className='tipoT'>
						{editar &&
							<select value={tipo} onChange={(e)=>{
								setTipo(e.target.value);
								setTipoS(e.target.value=="Workout" ? "Tiempo" : "Estrellas")
							}}>
								<option value='Workout'>Workout</option>
								<option value='Comida'>Comida</option>
								<option value='Lifestyle'>Lifestyle</option>
							</select>
						}
						{!editar &&
							<span>{workout.tipo}</span>
						}
					</div>
					{editar && tipo=="Workout" &&
						<div className='customR'>
							<label>
								<input type='radio' name='radio' defaultChecked={radio=="Custom"} onChange={(e)=>{
									if(e.target.checked){
										setRadio("Custom")
									}
								}}/>
								<span>Custom</span>
							</label>
							<label>
								<input type='radio' name='radio' defaultChecked={radio=="Benchmark"} onChange={(e)=>{
									if(e.target.checked){
										setRadio("Benchmark")
									}
								}}/>
								<span>Benchmark</span>
							</label>
						</div>
					}
				{(workout.id_workout==0 || editar) && tipo=="Workout" && radio=="Benchmark" &&
					<div>
						<div className='listaTit'>Benchmark</div>
						<div className='listaVer'>
							{lista.map(w=>{
								return(
								<div onClick={()=>{
									let id = workout.id_workout
									nombre.current.value = w.nombre;
									descripcion.current.value = w.descripcion;
									setTipo(w.tipo);
									setTipoS(w.tipoS);
								}}>
									<div>{w.tipo == "Workout" ? <div></div> : <div></div>}{w.tipo+" / "+w.nombre}</div>
									<div>{w.descripcion}</div>
									{/*<div>{w.tipoS}</div>*/}
								</div>);
							})
							}
						</div>
					</div>
				}
				<div className="divsEstilo">
					<div className="descripcion">
						<span>Nombre</span>
						{editar &&
							<textarea defaultValue={workout.nombre} ref={nombre}></textarea>
						}
						{!editar &&
							<span>{workout.nombre}</span>
						}
					</div>
					<div className="descripcion">
						<span>Descripción</span>
						{editar &&
							<textarea defaultValue={workout.descripcion} ref={descripcion}></textarea>
						}
						{!editar &&
							<span>{workout.descripcion}</span>
						}
					</div>
					<div className="descripcion">
						<span>Comentario</span>
						{editar &&
							<textarea defaultValue={workout.comentario} ref={comentario}></textarea>
						}
						{!editar &&
							<span>{workout.comentario}</span>
						}
					</div>
					<div className='scoreDon'>
						<div>
							<span>Tipo de Score</span>
							{editar &&
								<select value={tipoS} onChange={(e)=>{
									setTipoS(e.target.value);
								}}>
									{tipo != "Workout" &&
										<option value='Estrellas'>Estrellas</option>
									}
									{tipo == "Workout" &&
										<>
										<option value='Tiempo'>Tiempo</option>
										<option value='RyR'>Rondas y Repeticiones</option>
										<option value='Rondas'>Rondas</option>
										<option value='Repeticiones'>Repeticiones</option>
										<option value='Peso'>Peso</option>
										</>
									}
								</select>
							}
							{!editar &&
								<span>{workout.tipoS == "RyR" ? "Rondas y Repeticiones" : workout.tipoS}</span>
							}
						</div>
						{tipoS == "Estrellas" &&
							<div>
								<span>Score</span>
								<div className='estrellas'>
									{[...Array(5)].map((x,i)=>{
										return(
											<div className={'estrella'+ (i < scored ? ' activa':'')} onClick={()=>{
												cambiaScore(i+1);
											}} key={i}>
												<img src={estrella}/>
											</div>
										)
									})}
								</div>
							</div>
						}
						{tipoS!="Estrellas" &&
							<div>
								<span>Score</span>
								{editar &&
									<input defaultValue={workout.score} ref={score}/>
								}
								{!editar &&
									<span>{workout.score}</span>
								}
							</div>
						}
					</div>
					<div className='estadoW' onClick={cambiaEstado}>
						{completado &&
							<div className='completoW'>Completado</div>
						}
						{!completado &&
							<div className='incompletoW'>Completar</div>
						}
					</div>
				</div>
				<div className="botones unomas">
					{editar &&
						<>
							<div className="sig" onClick={guardar}>Guardar</div>
							<div className="cancel" onClick={cancelar}>Cancelar</div>
						</>
					}
					{!editar &&
						<>
							<div className="sig" onClick={()=>{setEditar(true)}}>Editar</div>
							<div className="cancel" onClick={borrar}>Borrar</div>
						</>
					}
				</div>
			</div>
		</div>
		</>
	);
}

export default Workouts;
export {Workout};
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams, useOutletContext} from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';
import VerFile from '../VerFile/VerFile';
import cuestionario from '../../img/cuestionario.png';
import like from '../../img/like.png';
import liked from '../../img/liked.png';
import save from '../../img/save.png';
import saved from '../../img/saved.png';
import teoria from '../../img/teoria.png';
import video from '../../img/video.png';
import './Secciones.css';


const Secciones = (()=>{
	const {id} = useParams();
    const [inscrito] = useOutletContext();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState({secciones:[]});
	const [buscar, setBuscar] = useState('');
	const nombre = useRef();
	useEffect(()=>{
		getData();
	}, [inscrito])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Seccion/getAll.php?id_curso="+id+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		console.log(data);
		setDatos(data || [])
		console.log(data);
	}
	return(
		<>
			{datos.inscrito &&
			<>
				<div className='buscador'>
					 
					<input 
					placeholder='Busca...'
					onChange={(e)=>{
						setBuscar(e.target.value);
					}}/>
				</div>
				<div>
					{datos.length == 0 &&
						<div>Aún no se ha agregado ninguna sección.</div>
					}
				</div>
				<div className='contSecciones'>
					<div className='secciones'>
						{
							datos.secciones.map((seccion, i)=>{
								if(buscar=='' || seccion.nombre.toLowerCase().includes(buscar.toLowerCase()))
								return(
									<Seccion seccion={seccion} inscrito={datos.inscrito} key={seccion.id_seccion}/>
								);
							})
						}
					</div>
				</div>
			</>
			}
			{!datos.inscrito &&
				<div>Suscríbete ahora para ver todo el contenido.</div>
			}
		</>
	);
})

const Seccion = ({seccion, inscrito=false})=>{
	const {id, idSeccion} = useParams();
	const [sub, setSub] = useState('video');
	const [mostrar, setMostrar] = useState(seccion.id_seccion == idSeccion);
	const navigate = useNavigate();
	return (
		<>
		<Link className={'progresoSeccion'+(mostrar? " mostrar" : "")} to={'/cursos/'+id+'/secciones/'+seccion.id_seccion} onClick={()=>{setMostrar(!mostrar)}}>
			<VerFile imagen={{ruta:seccion.ruta}} tipo="img"></VerFile>
			<div className='seccionNueva'>
				<span>{seccion.nombre}</span>
				<span className='opcSeccion'>
					{inscrito &&
						<span className='questions' onClick={(e)=>{
							e.preventDefault();
							navigate('/preguntas/'+seccion.id_seccion)
						}}>Dudas{seccion.respuestasNuevas && seccion.respuestasNuevas!=0 ? " ("+seccion.respuestasNuevas+")" : ""}</span>
					}
					<span className='porcentaje'>{seccion.progreso || '0'}%</span>
				</span>
			</div>
		</Link>
		{mostrar &&
			<>
			<div className='subSeccion'>
				<div className={ sub=="video" ? "activo" : ""} onClick={()=>{
					setSub("video")
				}}>Videos</div>
				{/*<div className={ sub=="cuestionario" ? "activo" : ""} onClick={()=>{
					setSub("cuestionario")
				}}>Quizz</div>*/}
				<div className={ sub=="multi" ? "activo" : ""} onClick={()=>{
					setSub("multi")
				}}>Descargable</div>
			</div>
			<div className='lista'>
			{
				seccion.lista.map(elemento=>{
					return(<Lista elemento={elemento} inscrito={inscrito} key={elemento.tipo+"-"+elemento.id_elemento} sub={sub}/>)
				})
			}
			</div>
			</>
		}
		</>
	);
}

const Lista = ({elemento, inscrito=false, sub})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [progreso, setProgreso] = useState(elemento.progreso || 0);
	const [duracion, setDuracion] = useState(0);
	const [megusta, setMegusta] = useState(elemento.liked=='true');
	const [guardado, setGuardado] = useState(elemento.saved=='true');
	const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	function meGusta(e){
		e.stopPropagation();
		if(megusta != elemento.liked)
		fetch("/cursos/clienteAPI/Videos/like.php?id_video="+elemento.id_elemento+"&like="+!megusta+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			elemento.liked = megusta;
			setMegusta(!megusta)
		});
	}
	function guardar(e){
		e.stopPropagation();
		if(guardado != elemento.saved)
		fetch("/cursos/clienteAPI/Videos/save.php?id_elemento="+elemento.id_elemento+"&tipo="+elemento.tipo+"&save="+!guardado+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			elemento.saved = guardado;
			setGuardado(!guardado)
		});
	}
	const navigate = useNavigate();
	const [modal, setModal] = useState(false);
	const tiposImg = {
		"video": video,
		"cuestionario": cuestionario,
		"multi": teoria,
	}
	function getExtension(){
		if(elemento.ruta){
			let a = elemento.ruta.split(".");
			return a[a.length-1].toLowerCase();
		}
		else{
			return "";
		}
	}
	function getFormato(ext){
		if(extension  === "jpeg" || extension === "jpg" || extension === "png" || extension === "tiff" || extension === "gif") {
			return "imagen";
		}
		else if(extension  === "mp4" || extension === "m4a"|| extension === "f4v" || extension  === "m4b" || extension  === "mov") {
			return "video";
		}
		else{
			return "archivo";
		}
	}
	const extension = getExtension();
	const formato = getFormato(extension);
	if(elemento.tipo==sub)
		return (
			<>
			{modal &&
				<div className='modalFile' onClick={(e)=>{
						if(e.target===e.currentTarget){
							setModal(false);
						}
					}}>
					{formato=="imagen" &&
						<img src={"/cursos/multi/"+elemento.ruta}/>
					}
					{formato=="archivo" &&
						<iframe src={"https://docs.google.com/viewer?url=/cursos/multi/"+elemento.ruta+"&embedded=true"} frameBorder="0"></iframe>
					}
				</div>
			}
			<div className={'progresoElem' + (elemento.tipo=="cuestionario" && elemento.videoTerminado!="true" && elemento.id_video != null ? " disabled" : "") +
			(elemento.tipo=="video" ? " elemVideo" : "")} onClick={(e)=>{
				if(elemento.tipo == "video"){
					navigate('/video/'+elemento.id_elemento);
				}
				else if(elemento.tipo == "cuestionario"){
					if(!elemento.id_video || elemento.videoTerminado=="true")
						navigate('/cuestionario/'+elemento.id_elemento);
				}
				else if(elemento.tipo== "multi"){
					window.open("/cursos/multi/"+elemento.ruta, "_blank");
				}
				else{
					setModal(true);
				}
			}}>
			{inscrito && elemento.ruta && elemento.tipo == "video" &&
				<div className={'image'+(elemento.completado=='true' ? " completado" : "")}>
					{elemento.completado=="true" &&
						<div className='visto'>
							<div>{elemento.tipo=="video" ? "Visto" : "Contestado"}</div>
						</div>
					}
					<img src={elemento.rutaMini ? "/cursos/img/"+elemento.rutaMini : tiposImg[elemento.tipo]}/>
					<div className='dura'>
						{(Math.floor(duracion/60)) + ":" + (Math.floor((duracion%60)) < 10 ? "0" + Math.floor((duracion%60)) : Math.floor((duracion%60)))}
					</div>
					<div className='megusta' onClick={meGusta}>
						<img src={megusta ? liked : like}/>
					</div>
					{elemento.completado!="true" &&
						<progress value={elemento.progreso/100}></progress>
					}
				</div>
			}
			{(!inscrito || !(elemento.ruta && elemento.tipo=="video")) && 
				<div className={'image'+(elemento.completado=="true" ? " completado" : "")}>
					{elemento.completado=="true" &&
						<div className='visto'>
							<div>{elemento.tipo=="video" ? "Visto" : "Contestado"}</div>
						</div>
					}
					{elemento.tipo == "video" &&
						<img src={elemento.rutaMini ? "/cursos/img/"+elemento.rutaMini : tiposImg[elemento.tipo]}/>
					}
					{elemento.tipo!="video" &&
						<img src={elemento.tipo == "cuestionario" && elemento.ruta ? "/cursos/img/"+elemento.ruta : tiposImg[elemento.tipo]}/>
					}
				</div>
			}
				<div className='descElem'>
					<div><b>{elemento.nombre}</b></div>
					<div>{elemento.descripcion}</div>
					{elemento.tipo=="cuestionario" && elemento.video != null &&
						<div>Se {elemento.videoTerminado=="true" ? "desbloqueó" : "desbloquea"} al terminar el video: {elemento.video}</div>
					}
				</div>
				<div className='guardar' onClick={guardar}>
					<img src={guardado ? saved : save}/>
				</div>
			</div>
			</>
		);
}

export default Secciones;
import React, { useState, useEffect, useRef, useContext } from 'react';
import {useNavigate, Link} from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';
import Alert from '../../components/Alert/Alert';
import './Calendario.css';

import flecha from '../../img/flechaDerecha.png'; 

const Calendario = ({setModo, modo})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [alert, setAlert] = useState({})
	const [fecha, setFecha] = useState(new Date())
	const navigate = useNavigate();

	useEffect(()=>{
		if(token=="")
			navigate('/', {replace:true});
	}, [])

	function borrar(){
		fetch("/cursos/clienteAPI/Calendario/borrarAll.php?token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			//setDatos({})
		})
	}
	return (
		<>
			{alert.ver &&
				<Alert alert={alert} setAlert={setAlert}/>
			}
			<div className='botonArriba'>
				<div className='botones'>
					<Link className='sig' to={'/perfil/workouts/buscar'}>
						Buscar
					</Link>
					<Link className='sig' to={'/perfil/workouts/lista'}>
						Ver Lista
					</Link>
				</div>
			</div>
			<div className='contCalendario'>
				<Mes fecha={fecha} setFecha={setFecha} key={fecha}/>
			</div>
		</>
	);
}
const Mes = ({fecha, setFecha})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState({})
	const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
	const mes = fecha.getMonth();
	const year = fecha.getFullYear();
	const diaI = new Date(year, mes, 1);
	const diaF = new Date(year, mes + 1, 0);
	const filas = Math.ceil((diaF.getDate() + diaI.getDay()) / 7);
	const today = new Date();
	var hoy = -1;
	if(today.getMonth() == mes && today.getFullYear() == year){
		hoy = today.getDate()-1;
	}
	const [dia, setDia] = useState(hoy+1 || 1)
	var c = 0;
	useEffect(()=>{
		getData();
	}, [fecha])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Calendario/getAll.php?token="+token+"&mes="+(mes+1)+"&year="+year)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			return json;
		});
		setDatos(data.dias || {});
	}
	return(
		<>
		<div className='fechaOpc'>
			<div>
				<span onClick={()=>{
					setFecha(new Date());
				}}>Hoy</span>
			</div>
			<div>
				<span onClick={()=>{
					setFecha(new Date(fecha.setMonth(mes-1)))
				}}><span><img src={flecha}/></span></span>
				<span>{meses[mes]}</span>
				<span onClick={()=>{
					setFecha(new Date(fecha.setMonth(mes+1)))
				}}><span className='alRe'><img src={flecha}/></span></span>
			</div>
			<div>
				<span onClick={()=>{
					setFecha(new Date(fecha.setFullYear(year-1)))
				}}><span><img src={flecha}/></span></span>
				<span>{year}</span>
				<span onClick={()=>{
					setFecha(new Date(fecha.setFullYear(year+1)))
				}}><span className='alRe'><img src={flecha}/></span></span>
			</div>
		</div>
		<div className='calendario'>
			<div>
				<span>Domingo</span><span>D</span>
				<span>Lunes</span><span>L</span>
				<span>Martes</span><span>M</span>
				<span>Miércoles</span><span>M</span>
				<span>Jueves</span><span>J</span>
				<span>Viernes</span><span>V</span>
				<span>Sábado</span><span>S</span>
			</div>
			{
			[...Array(filas)].map((fila, i)=>{
				return(<div key={i}>
					{
						[...Array(7)].map((x, j)=>{
							const aux = c;
							return (
							<Link 
							key={i*7+j}
							to={'/perfil/workouts/'+((aux+1 < 10 ? "0"+(aux+1) : aux+1)+"-"+(mes+1 < 10 ? "0"+(mes+1) : mes+1)+"-"+year)} 
							className={(dia===c+1 && (i != 0 || j >= diaI.getDay()) ? "seleccionado" : "")+(hoy===c && (i != 0 || j >= diaI.getDay()) ? " hoy" : "") + (datos[c+1] && c < diaF.getDate() && (i != 0 || j >= diaI.getDay()) ? " conW" : "")}>
								<span>{c < diaF.getDate() && (i != 0 || j >= diaI.getDay()) ? ++c : ""}</span>
							</Link>);
						})
					}
				</div>);
			})
			}
		</div>
		<Dia datos={datos[dia]}/>
		</>
	)
}
const Dia = ({datos=[]})=>{
	return(
	<div className='listaDia'>
		{datos?.length === 0 &&
			<div>No hay resultados para este día</div>
		}
		{datos?.slice(0,3)?.map(workout=>{
			return(
			<div key={workout.id_workout} className='workoutDia'>
				<span>{workout.tipo} / </span>
				<span>{workout.nombre}</span>
			</div>)
		})
		}
	</div>);
}
export default Calendario;
import React, { useState, useEffect, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Cuestionario from '../Cuestionario/Cuestionario';
import './Cuestionarios.css';
import defaultQuizz from '../../img/noQuizz.png';

const Cuestionarios = (()=>{
	document.title = "Cursos";
	const navigate = useNavigate();
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [cuestionarios, setCuestionarios] = useState([]);
	const [more, setMore] = useState(false);
	const [page, setPage] = useState(0);
	const [buscar, setBuscar] = useState('');
	const [cuestionario, setCuestionario] = useState({});
	const [editar, setEditar] = useState(false);
	useEffect(()=>{
		getData();
	}, [])
	useEffect(()=>{
		if(editar){
			document.body.addEventListener("keydown", cerrar)
		}
		else{
			document.body.removeEventListener("keydown", cerrar)
		}
	}, [editar])
	useEffect(()=>{
		if(page!=0)
			getData(true);
	},[page])
	function cerrar(e){
		if(e.code=="Escape"){
			if(document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA"){
				setEditar(false);
			}
		}
	}
	useEffect(()=>{
		getData();
	}, [buscar])
	async function getData(concat){
		const data = await fetch("/cursos/clienteAPI/Cuestionario/allquizz.php?token="+token+"&page="+page+"&buscar="+buscar)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		console.log(data);
		if(concat)
			setCuestionarios(cuestionarios.concat(data["cuestionarios"].splice(0,5)));
		else
			setCuestionarios(data["cuestionarios"].splice(0,5));
		setMore(data["final"] ? false : true)
	}
	return(
		<>
			<div className='buscador'>
				 
				<input 
				placeholder='Busca...'
				onChange={(e)=>{
					setPage(0)
					setBuscar(e.target.value);
				}}/>
			</div>
			<div className='contCuestionarios'>
			{editar &&
				<Cuestionario 
					setEditar={setEditar}
					setCuestionarios={setCuestionarios}
					cuestionarios={cuestionarios}
					cuestionario={cuestionario}
				/>
			}
			{
			cuestionarios.map((cuestionario, i)=>{
				cuestionario.orden = i+1;

				if(buscar=='' || cuestionario.nombre.toLowerCase().includes(buscar.toLowerCase()))
				return (
					<div className={"infoCuestionario"+(cuestionario.completado=="true" ? " completado" : "")+(cuestionario.videoTerminado!="true" && cuestionario.video != null ? " disabled" : "")} key={cuestionario.id_cuestionario} onClick={()=>{
						if(cuestionario.videoTerminado=="true" || cuestionario.video == null)
							navigate('/cuestionario/'+cuestionario.id_cuestionario);
					}}>
						<div className='no450'>{cuestionario.nombre}</div>
						<div className='divAuxilio'>
							<div className="divImg">
								<img src={cuestionario.ruta ? "/cursos/img/"+cuestionario.ruta : defaultQuizz}/>
							</div>
							<div className='columnrow'>
								<div className='no750'>{cuestionario.nombre}</div>
								<div>Preguntas: {cuestionario.numPreguntas || 0}</div>
							</div>
						</div>
						{cuestionario.video != null &&
							<div className='desbloq'>Se {cuestionario.videoTerminado=="true" ? "desbloqueó" : "desbloquea"} al terminar el video: {cuestionario.video}</div>
						}
					</div>
				);
			})
			}
			{more &&
				<div className='botones'>
					<div className='sig' onClick={()=>{
						setPage(page+1);
					}}>Cargar más</div>
				</div>
			}
			{!more &&
				<div className='botones'>Estos son todos los cuestionarios</div>
			}
			{cuestionarios.length == 0 &&
				<div>No hay cuestionarios disponibles</div>
			}
			</div>
		</>
	);
})

export default Cuestionarios;
import React, { useState, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import {useNavigate, Link} from 'react-router-dom';
import './Restablecer.css';

export default function Restablecer() {
  const navigate = useNavigate();
  const [enviado, setEnviado] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const correo = useRef();
  function confirmar(){
    if(disabled){
      return false;
    }
    if(
      !correo.current.reportValidity()
    )
      return false;
    setDisabled(true);
    let datos = {
      "correo" : correo.current.value,
    }
    fetch("/cursos/mail.php", {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        },
        body: JSON.stringify(datos)
      })
    .then(response=> response.json())
    .then(json=>{
      if(json["status"]){
        setEnviado(true);
      }
      else{
        setEnviado(false);
        setError(true);
        setTimeout(function(){
          setError(false);
        },3000)
      }
      setDisabled(false);
    })
    .catch(e=>{
      setDisabled(false);
    })

  }
  return(
    <>
    {!enviado &&
      <div className="contRe">
        <div className="registro">
          {error &&
            <div className='registroError'>Ocurrió un error al intentar registrate, por favor intenta de nuevo.</div>
          }
          <h2>Ingresa el correo con el que te registraste:</h2>
          <form>
            <label>
              <input className="inputDon" type="email" onChange={()=>{
                correo.current.setCustomValidity("");
              }} ref={correo} placeholder='Correo' required/>
            </label>
            <div className='botones'>
              <div className={'sig'+(disabled ? " disabled" : "")} onClick={confirmar}>Siguiente</div>
            </div>
          </form>
        </div>
      </div>
    }
    {enviado &&
      <div className="contRe">
        <div className='registro'>
          <div className='registroExito'>Te fue enviado un correo con las instrucciones para restablecer tu contraseña.</div>
          <div className='botones'>
              <div className='sig' onClick={()=>{
                navigate('/login', {replace:true})
              }}>Regresar</div>
          </div>
        </div>
      </div>
    }
    </>
  )
}

import React, { useState, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import {useNavigate, Link} from 'react-router-dom';
import './Registro.css';

export default function Registro() {
  const {setLogin, setToken, token} = useContext(LoginContext);
  const [registro, setRegistro] = useState(false);
  const [error, setError] = useState("");
  const [datos, setDatos] = useState({});
  const [disponible, setDisponible] = useState({});
  const nombre = useRef();
  const correo = useRef();
  const usuario = useRef();
  const password = useRef();
  const passwordC = useRef();
  const navigate = useNavigate();
  function registrar(){
    if(
      !nombre.current.reportValidity() || 
      !correo.current.reportValidity() || 
      !usuario.current.reportValidity() || 
      !password.current.reportValidity() || 
      !passwordC.current.reportValidity()
    )
      return false;
    if(!disponible["correo"]){
      correo.current.setCustomValidity("Este correo ya ha sido registrado");
      correo.current.reportValidity();
      return false;
    }
    if(!disponible["usuario"]){
      usuario.current.setCustomValidity("Este usuario no está disponible");
      usuario.current.reportValidity();
      return false;
    }
    if(password.current.value != passwordC.current.value){
      password.current.setCustomValidity("Las contraseñas deben coincidir");
      password.current.reportValidity();
      return false;
    }
    let datos = {
      "nombre" : nombre.current.value,
      "correo" : correo.current.value,
      "usuario" : usuario.current.value,
      "password" : password.current.value,
    }
    fetch("/cursos/clienteAPI/Registro/registrar.php", {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        },
        body: JSON.stringify(datos)
      })
    .then(response=> response.json())
    .then(json=>{
      if(json["status"]){
        setRegistro(true);
      }
      else{
        setError(true);
        setTimeout(function(){
          setError(false);
        },3000)
      }
    })

  }
  function revisar(e, tipo){
    if(e.target.value.length>5){
      fetch("/cursos/clienteAPI/Registro/disponible.php?"+tipo+"="+e.target.value)
      .then(response=>response.json())
      .then(json=>{
        setDisponible(aux=>{
          aux[tipo] = json["total"] == 0;
          return {...aux}
        });
      })
      .catch((error)=>{
        console.log(error)
      })
    }
  }
  return(
    <>
    {!registro &&
      <div className="contRe">
        <div className="registro">
          {error &&
            <div className='registroError'>Ocurrió un error al intentar registrate, por favor intenta de nuevo.</div>
          }
          <h1>Regístrate:</h1>
          <form>
            <label>
              <input className="inputDon" type="text" pattern=".{3,}" title="Mínimo 3 caracteres" ref={nombre} placeholder='Nombre' required/>
            </label>
            <label>
              <input className="inputDon" type="email" onChange={()=>{
                correo.current.setCustomValidity("");
              }} onInput={(e)=>{revisar(e, "correo")}} ref={correo} placeholder='Correo' required/>
              {correo.current?.value.length > 5 &&
                <div className={disponible["correo"] ? "disponible" : "nodisponible"}>{disponible["correo"] ? "Este correo está disponible" : "Este correo no está disponible"}</div>
              }
            </label>
            <label>
              <input className="inputDon" type="text" onChange={()=>{
                usuario.current.setCustomValidity("");
              }} onInput={(e)=>{revisar(e, "usuario")}} pattern=".{6,}" title="Mínimo 6 caracteres" ref={usuario} placeholder='Usuario' required/>
              {usuario.current?.value.length > 5 &&
                <div className={disponible["usuario"] ? "disponible" : "nodisponible"}>{disponible["usuario"] ? "Este usuario está disponible" : "Este usuario no está disponible"}</div>
              }
            </label>
            <label>
              <input className="inputDon" type="password" onChange={()=>{
                password.current.setCustomValidity("");
              }} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" ref={password} placeholder='Contraseña' required/>
            </label>
            <label>
              <input className="inputDon" type="password" onChange={()=>{
                password.current.setCustomValidity("");
              }} ref={passwordC} placeholder='Confirmar Contraseña' required/>
              <div>Debe incluir al menos una letra mayuscula, una minúscula y un número. Al menos 8 caracteres</div>
            </label>
            <div className='botones'>
              <div className='sig' onClick={registrar}>Registrar</div>
            </div>
          </form>
          <div>
            <div>Si ya estás registrado...</div>
            <div className='botones'>
              <div className='sig' onClick={()=>{
                navigate('/login', {replace:true})
              }}>Inicia sesión</div>
            </div>
          </div>
        </div>
      </div>
    }
    {registro &&
      <div className="contRe">
        <div className='registro'>
          <div className='registroExito'>¡Te has registrado correctamente! Ya puedes iniciar sesión</div>
          <div className='botones'>
              <div className='sig' onClick={()=>{
                navigate('/login', {replace:true})
              }}>Inicia sesión</div>
          </div>
        </div>
      </div>
    }
    </>
  )
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import './Ganadas.css';
import defaultSubir from '../../img/defualtSubir.png';
import izq from '../../img/izq.png';
import der from '../../img/der.png';

const Ganadas = (({insignias, setInsignias})=>{
	const swiper = useRef();
	const activo = useRef();
	const [indice, setIndice] = useState(0);
	function cambiar(sig){
        if(sig){
        	if(indice+1 < insignias.length){
        		setIndice(indice+1);
        		swiper.current.scrollLeft = activo.current.offsetWidth*(indice+1);
        	}
        }
        else{
        	if(indice-1 >= 0){
        		setIndice(indice-1);
        		swiper.current.scrollLeft = activo.current.offsetWidth*(indice-1);
        	}
        }

    }
    var inicial = 0;
    var final = 0;
    function mouseDown(e){
        e.preventDefault();
        inicial = e.clientX;
    }
    function touchStart(e){
        inicial = e.touches[0].clientX;
    }
    function mouseMove(e){
        if(inicial==0)
            return false;
        final = e.clientX;
        let pantalla = indice == 0 ? 0 : activo.current.offsetWidth;
        swiper.current.scrollLeft = pantalla - (final - inicial);
    }
    function touchMove(e){
        if(inicial==0)
            return false;
        final = e.touches[0].clientX;
        let pantalla = indice == 0 ? 0 : activo.current.offsetWidth;
        swiper.current.scrollLeft = pantalla - (final - inicial);
    }
    function mouseUp(e){
        if(inicial == 0)
            return false;
        let pantalla = indice == 0 ? 0 : activo.current.offsetWidth;
        final = e.clientX;
        if(Math.abs(final - inicial) > activo.current.offsetWidth/4){
            if(final - inicial > 0)
                cambiar(false);
            else
                cambiar(true);
        }
        else{
            swiper.current.scrollLeft = pantalla;
        }
        inicial = 0;
    }
    function touchEnd(e){
        if(inicial == 0)
            return false;
        let pantalla = indice == 0 ? 0 : activo.current.offsetWidth;
        if(Math.abs(final - inicial) > activo.current.offsetWidth/4){
            if(final - inicial > 0)
                cambiar(false);
            else
                cambiar(true);
        }
        else{
            swiper.current.scrollLeft = pantalla;
        }
        inicial = 0;
    }
	if(insignias.length!=0){
		return(
			<div className='modalGanadas'>
				<div className='contGanadas' ref={activo}>
					<div className='scrollGanadas' ref={swiper}
						onMouseDown={mouseDown}
                        onMouseUp={mouseUp}
                        onMouseMove={mouseMove}
                        onMouseEnter={mouseUp}
                        onMouseLeave={mouseUp}
                        onTouchStart={touchStart}
                        onTouchMove={touchMove}
                        onTouchEnd={touchEnd}
					>
						{insignias.map((insignia, i)=>{
							return(<Insignia insignia={insignia} indice={i} key={insignia.id_insignia}/>);
						})}
					</div>
					{insignias.length > 1 && indice!=0 &&
						<div className='izq' onClick={()=>cambiar(false)}>
							<img src={izq}/>
						</div>
					}
					{insignias.length > 1 && indice!=insignias.length-1 &&
						<div className='der' onClick={()=>cambiar(true)}>
							<img src={der}/>
						</div>
					}
					<div className='botones'>
						<div className='sig' onClick={()=>setInsignias([])}>Aceptar</div>
					</div>
				</div>
			</div>
		);
	}
})

const Insignia = (({insignia, indice})=>{
	const [clase, setClase] = useState("bloqueada");
	useEffect(()=>{
		setTimeout(function(){
			setClase("ganada");
		}, 1500+indice*500)
	}, [])
	return(<>
		<div className={clase}>
			<div>{insignia.nombre}</div>
			<div className='divImg divMancha'>
				<img src={insignia.ruta ? '/cursos/img/'+insignia.ruta : defaultSubir}/>
			</div>
			<div>{insignia.descripcion}</div>
		</div>
	</>);
});

export default Ganadas;
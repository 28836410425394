import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useParams, Outlet } from 'react-router-dom';
import Curso from '../Curso/Curso';
import './CursoNav.css';
import Insignias from '../../img/Insignias.png';
import InsigniasC from '../../img/Insigniasred.png';
import Secciones from '../../img/secciones.png';
import SeccionesC from '../../img/seccionesred.png';


const CursoNav = (({modo})=>{
	const {id} = useParams();
	return(
		<>
		<nav className="navCurso">
			<span>
				<NavLink to={'/cursos/'+id+"/secciones"}>
					<img src={modo=="oscuro" ? Secciones : SeccionesC}/>
					<span>Secciones</span>
				</NavLink>
			</span>
			<span>
				<NavLink to={'/cursos/'+id+"/insignias"}>
					<img src={modo=="oscuro" ? Insignias : InsigniasC}/>
					<span>Insignias</span>
				</NavLink>
			</span>
		</nav>
		</>
	);
})


export default CursoNav;
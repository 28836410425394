import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Route, Routes, Outlet, Link, NavLink, useNavigate } from 'react-router-dom';
import LoginContext from '../../components/Login/LoginContext';
import sinFoto from '../../img/sinfoto.png';
import Edit from '../../img/edit.png';
import logros from '../../img/logros.png';
import liked from '../../img/liked.png';
import saved from '../../img/saved.png';
import iconoInsignia from '../../img/saved.png';
const UserBar = ()=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const navigate = useNavigate();
	const [perfil, setPerfil] = useState({});
	useEffect(()=>{
		getData();
	},[token])

	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Perfil/get.php?token="+token)
		.then(response=> response.json())
		.then(json=>{
			return json;
		});
		setPerfil(data || {});
	}
	if(token=="")
		return(
			<>
				<div className='miPerfil iSesion'>
					<div>Inicia sesión y obtén acceso a tus cursos.</div>
					<div className='botones'>
						<Link className="sig" to='/login'>
						Iniciar sesión
						</Link>
					</div>
				</div>
				<Outlet/>
			</>
		)
	else
		return (
			<>
				<div className='miPerfil'>
					<Link to='/perfil/' className='profile'>
						<div className='imgPerfil'>
							<img src={perfil.ruta ? "/cursos/clienteAPI/img/"+perfil.ruta : sinFoto}/>
						</div>
						<div>
							<div>{perfil.nombre}</div>
						</div>
					</Link>
					<div className='opcPerfil'>
						<NavLink to='/conseguidas' className=''>
							{perfil.ganadas || 0}
							<img src={logros}/>
						</NavLink>
						<NavLink to='/favoritos/'><img src={liked}/></NavLink>
						<NavLink to='/guardados/'><img src={saved}/></NavLink>
					</div>
				</div>
				<Outlet/>
			</>
		)
}
export default UserBar;
import React, { useState, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import {useNavigate, Link, useParams} from 'react-router-dom';
import './Restablecer.css';

export default function Restablecer() {
  const {codigo} = useParams();
  const navigate = useNavigate();
  const [cambiada, setCambiada] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const password = useRef();
  const passwordC = useRef();

  function cambiar(){
    if(disabled){
      return false;
    }
    if(
      !password.current.reportValidity() || 
      !passwordC.current.reportValidity()
    )
      return false;
    if(password.current.value != passwordC.current.value){
      password.current.setCustomValidity("Las contraseñas deben coincidir");
      password.current.reportValidity();
      return false;
    }
    setDisabled(true);
    let datos = {
      "codigo" : codigo,
      "password" : password.current.value,
    }
    fetch("/cursos/clienteAPI/Restablecer/cambiar.php", {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        },
        body: JSON.stringify(datos)
      })
    .then(response=> response.json())
    .then(json=>{
      if(json["status"]){
        setCambiada(true);
      }
      else{
        setCambiada(false);
        setError(true);
        setTimeout(function(){
          setError(false);
        },3000)
      }
      setDisabled(false);
    })
    .catch(e=>{
      setDisabled(false);
    })

  }
  return(
    <>
    {!cambiada &&
      <div className="contRe">
        <div className="registro">
          {error &&
            <div className='registroError'>Ocurrió un error, por favor intenta de nuevo.</div>
          }
          <h2>Restablece tu contraseña:</h2>
          <form>
            <label>
              <input className="inputDon" type="password" onChange={()=>{
                password.current.setCustomValidity("");
              }} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" ref={password} placeholder='Contraseña' required/>
            </label>
            <label>
              <input className="inputDon" type="password" onChange={()=>{
                password.current.setCustomValidity("");
              }} ref={passwordC} placeholder='Confirmar Contraseña' required/>
              <div>Debe incluir al menos una letra mayuscula, una minúscula y un número. Al menos 8 caracteres</div>
            </label>
            <div className='botones'>
              <div className={'sig'+(disabled ? " disabled" : "")} onClick={cambiar}>Siguiente</div>
            </div>
          </form>
        </div>
      </div>
    }
    {cambiada &&
      <div className="contRe">
        <div className='registro'>
          <div className='registroExito'>Tu contraseña fue cambiada con éxito, ya puedes iniciar sesión.</div>
          <div className='botones'>
              <div className='sig' onClick={()=>{
                navigate('/login', {replace:true})
              }}>Inciar sesión</div>
          </div>
        </div>
      </div>
    }
    </>
  )
}

import React, { useState, useEffect, useRef } from 'react';
import useToken from '../../useToken';
import {useParams} from 'react-router-dom';
import imgCargando from '../../img/cargando.gif';
import defaultSubir from '../../img/defualtSubir.png';
import defaultPDF from '../../img/defaultPDF.png';
import defaultArchivo from '../../img/defaultFile.png';


const VerFile = (({ imagen, tipo, setActivo, indice, activo})=>{
	const [modal, setModal] = useState(false);
	const aceptar = {
		"img" : "image/*",
		"video" : "video/*",
		"pdf" : "file/pdf",
	}
	function getExtension(){
		if(imagen.ruta){
			let a = imagen.ruta.split(".");
			return a[a.length-1].toLowerCase();
		}
		else{
			return "";
		}
	}
	function getFormato(ext){
		if(extension  === "jpeg" || extension === "jpg" || extension === "png" || extension === "tiff" || extension === "gif") {
            return "imagen";
        }
        else if(extension  === "mp4" || extension === "m4a"|| extension === "f4v" || extension  === "m4b" || extension  === "mov") {
            return "video";
        }
		else{
			return "archivo";
		}
	}
	const extension = getExtension();
	const formato = getFormato(extension);
	const {id} = useParams();
	useEffect(()=>{
		if(modal){
			document.body.addEventListener("keydown", cerrar)
		}
		else{
			document.body.removeEventListener("keydown", cerrar)
		}
	}, [modal])

	function cerrar(e){
		if(e.code=="Escape"){
			if(document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA"){
				setModal(false);
			}
		}
	}
	return(
		<>
		{modal &&
			<div className='modalFile' onClick={(e)=>{
					if(e.target===e.currentTarget){
						setModal(false);
					}
				}}>
				{(tipo == "img" || formato=="imagen") &&
					<img src={"/cursos/"+tipo+"/"+imagen.ruta}/>
				}
				{tipo != "img" && formato=="archivo" &&
					<iframe src={"https://docs.google.com/viewer?url=/cursos/"+tipo+"/"+imagen.ruta+"&embedded=true"} frameBorder="0"></iframe>
				}
			</div>
		}
		<div className={'divImg'+(activo ? " activo" : "")} onClick={(e)=>{
            console.log("click");
			setActivo(indice);
		}}>
		{!imagen.ruta &&
			<img src={defaultSubir}/>
		}
		{imagen.ruta && formato=="imagen" &&
			<img src={"/cursos/"+tipo+"/"+imagen.ruta}/>
		}
		{imagen.ruta && formato=="archivo" &&
			<img src={defaultArchivo}/>
		}
		{imagen.ruta && formato=="video" &&
			<video preload='metadata' src={"/cursos/"+tipo+"/"+imagen.ruta+"#t=0.001"} ></video>		
		}
		</div>
		</>
	);
});

export default VerFile;
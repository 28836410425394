import React, { useState, useEffect, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, useParams } from 'react-router-dom';
import './InsigniasTotales.css';
import defaultInsignia from '../../img/defaultInsignia.png';

const InsigniasTotales = (()=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [datos, setDatos] = useState({insignias:[]});
	const [buscar, setBuscar] = useState('');
	const [nuevo, setNuevo] = useState(false);
	useEffect(()=>{
		getData();
	}, [])
	async function getData(){
		if(id!="nuevo"){
			const data = await fetch("/cursos/clienteAPI/Insignia/totales.php?token="+token)
			.then(response=> response.json())
			.then(json=>{
				if(json["errAuth"]){
					//setToken({})
					return false;
				}
				return json;
			});
			setDatos(data || {});
		}
	}
	return(
		<>
			<div className='buscador'>
				<input 
				placeholder='Busca...'
				onChange={(e)=>{
					setBuscar(e.target.value);
				}}/>
			</div>
			<div className='contInsignias'>
			{
			datos.insignias.map((insignia, i)=>{
				insignia.orden = i+1;

				if(buscar=='' || insignia.nombre.toLowerCase().includes(buscar.toLowerCase())){
					return (<Insignia insignia={insignia} key={insignia.id_insignia} setDatos={setDatos} datos={datos}/>);
				}
			})
			}
			{datos.insignias.length == 0 &&
				<div>No hay insignias.</div>
			}
			</div>
		</>
	);
})

const Insignia = (({insignia, setNuevo, setDatos, datos})=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const editar = false;
	const [imagen, setImagen] = useState({id_archivo : insignia.id_archivo , ruta : insignia.ruta});
	const [filtro, setFiltro] = useState({tipo: insignia.tipo || "curso", id: insignia.condicion, "id_seccion": insignia.id_seccion});
	const [condicion, setCondicion] = useState("todo el curso");
	useEffect(()=>{
		if(filtro.tipo=="curso")
			setCondicion("todo el curso");
		else{
			let sec = datos.secciones.find(x=>{
				return x.id_seccion == filtro.id_seccion
			})
			if(filtro.tipo=="seccion"){
				setCondicion("toda la sección '" + sec.nombre + "' (videos y cuestionarios)");
			}
			else if(filtro.tipo=="seccion-videos"){
				setCondicion("todos los videos de la sección '" + sec.nombre + "'");
			}
			else if(filtro.tipo=="seccion-cuestionarios"){
				setCondicion("todos los cuestionarios de la sección '" + sec.nombre + "'");
			}
			else if(filtro.tipo=="video"){
				let elem = sec.videos.find(x=>{
					return x.id_multimedia==filtro.id;
				})
				setCondicion("el video '" + elem.nombre + "'");
			}
			else if(filtro.tipo=="cuestionario"){
				let elem = sec.cuestionarios.find(x=>{
					return x.id_cuestionario==filtro.id;
				})
				setCondicion("el cuestionario '" + elem.nombre + "'");
			}
		}
	}, [filtro])
	return(<>
		<div className={'infoInsignia insigniasTotales'+(insignia.ganada==1 ? " conseguida" : "")}>
			<div>
				<div>
					<span>Se otorga al terminar </span>
					<span>{condicion}</span>
				</div>
			</div>
			<div className='descInsignia'>
				<div className='divImg' onClick={(e)=>{
					//showModalImg(curso?.imagen?.ruta || "../../../public/defaultCurso.png");
				}}>
					<img src={imagen.ruta ? "/cursos/img/"+imagen.ruta : defaultInsignia}/>
					{insignia.ganada==1 ? "" : <span className='oscInsignia'>?</span>}
				</div>
				<div className='datosInsignia'>
					<div>
						<span>{insignia.nombre}</span>
					</div>
				</div>
				<div className='botones'>
					<Link to={'/cursos/'+insignia.id_curso+"/secciones/"+insignia.id_seccion} className='sig'>Ir al Curso</Link>
				</div>
			</div>
		</div>
	</>);
});

export default InsigniasTotales;
import React, { useState, useEffect, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cuestionario from '../../img/cuestionario.png';
import video from '../../img/video.png';
import file from '../../img/file.png';
import './Preguntas.css';


import volver from '../../img/volver.png';

const Preguntas = (({tipo})=>{
	const {id} = useParams();
	const navigate = useNavigate();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [seccion, setSeccion] = useState([]);
	const [buscar, setBuscar] = useState('');
	useEffect(()=>{
		getData();
	}, [])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Pregunta/getAll.php?id="+id+"&tipo="+tipo+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setSeccion(data || []);
	}
	if(seccion.inscrito)
		return(
			<>
			{id && id!=0 &&
				<div className="atras" onClick={()=>{
					navigate(-1);
				}}><img src={volver} />Secciones</div>
			}
			<div className='buscador'>
				 
				<input 
				placeholder='Busca...'
				onChange={(e)=>{
					setBuscar(e.target.value);
				}}/>
			</div>
			<div className='contPreguntas preguntasEnviar'>
			{tipo == "seccion" &&
				<div className='divSeccion'>
					<div>{seccion.nombre}</div>
				</div>
			}
			{
				seccion?.elementos?.map(elemento=>{
					return(
						<Elemento elemento={elemento} tipo={tipo} key={elemento.id_elemento+"-"+elemento.tipo}/>
					);
				})
			}
			</div>
			</>
		);
	else if(seccion.inscrito === false)
		return(
			<div className='forbidden'>
				{token != "" &&
					<>
						<div>Lo sentimos. No tienes acceso a esta sección</div>
						<div>Consigue acceso suscribiendote a este curso</div>
						<div className="botonsigLA"><Link to={'/cursos/'+seccion.id_curso}>Ir al curso</Link></div>
					</>
				}
				{token == "" &&
					<>
						<div>Inicia sesión para poder acceder a las preguntas de tus cursos.</div>
						<div className='botones'>
							<Link className="sig" to='/login'>
								Iniciar sesión
							</Link>
						</div>
					</>
				}
			</div>
		);
})

const Elemento = ({elemento, tipo})=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [mostrar, setMostrar] = useState(false);
	const [preguntas, setPreguntas] = useState(elemento.preguntas || []);
	const pregunta = useRef(null)
	function preguntar(){
		if(!pregunta.current.reportValidity())
			return false;
		let res = pregunta.current.value;
		let data = {"pregunta" : res, id_elemento : elemento.id_elemento, tipo : elemento.tipo};
		fetch("/cursos/clienteAPI/Pregunta/set.php?token="+token, {
	      method: 'POST',
	      mode: 'cors',
	      headers: {
	        Accept: 'application/json',
	        'Content-Type': 'application/json',
	        'Access-Control-Allow-Origin':'*'
	      },
	      body: JSON.stringify(data)
	    })
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			data["id_pregunta"] = json["id_pregunta"];
			data["fecha"] = json["fecha"];
			data["respuestas"] = [];
			pregunta.current.value = "";
			setPreguntas([data,...preguntas]);
			alert("La pregunta ha sido enviada.");
		})
	}
	return (
		<>
		<div className={'progresoElemento'+(mostrar? " mostrar" : "")} onClick={()=>{setMostrar(!mostrar)}}>
			<span>
				<div className='divImg'>
					<img src={elemento.tipo == "cuestionario" ? cuestionario : elemento.tipo=="video" ? video : file}/>
				</div>
			</span>
			<span>{elemento.nombre}</span>
			<span><i>{preguntas.length}</i></span>
		</div>
		{mostrar &&
			<div className='preguntas'>
				<div>
					<div>
						<textarea placeholder='Escribe tu pregunta' ref={pregunta} required>
							
						</textarea>
					</div>
					<div className='botones'>
						<div className='sig' onClick={()=>{
							preguntar();
						}}>
							Enviar Pregunta
						</div>
					</div>
				</div>
			{
				preguntas?.map(pregunta=>{
					return(<Pregunta pregunta={pregunta} key={pregunta.id_pregunta}/>)
				})
			}
			</div>
		}
		</>
	);
}
const Pregunta = ({pregunta})=>{
	const {id} = useParams();
	const [mostrar, setMostrar] = useState(id && id!=0);
	return (
		<>
		<div className={"pregunta"+(pregunta.resuelta == 'true'? ' resuelta' : '')} onClick={()=>{setMostrar(!mostrar)}}>
			<div className={pregunta.resuelta == 'true' ? "infoPreg resuelta" : "infoPreg sinresolver"}>
				<div>
					{pregunta.fecha}
				</div>
				<div>
					{pregunta.pregunta}
				</div>
			</div>
			{
				pregunta?.respuestas?.map(respuesta=>{
					return(
						<Respuesta respuesta={respuesta} key={respuesta.id_respuesta}/>
					);
				})
			}
		</div>
		{mostrar && true
		}
		</>
	);
}

const Respuesta = ({respuesta})=>{
	return(
		<div className='respuesta'>
			<div>
				{respuesta.fecha}
			</div>
			<div>
				{respuesta.respuesta}
			</div>
		</div>
	);
}

export default Preguntas;
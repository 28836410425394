import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login/Login';
import Notificacion from './components/Notificacion/Notificacion';
import Registro from './components/Registro/Registro';
import Restablecer from './components/Restablecer/Restablecer';
import Restablece from './components/Restablecer/Restablece';
import LoginContext from './components/Login/LoginContext';
import NavBar from './components/NavBar';
import UserBar from './components/UserBar/UserBar';
import Inicio from './components/Inicio/Inicio';
import Cursos from './components/Cursos/Cursos';
import CursoNav from './components/CursoNav/CursoNav';
import Curso from './components/Curso/Curso';
import Inscribir from './components/Inscribir/Inscribir';
import Secciones from './components/Secciones/Secciones';
import Cuestionario from './components/Cuestionario/Cuestionario';
import Shorts from './components/Shorts/Shorts';
import Preview from './components/Preview/Preview';
import Cuestionarios from './components/Cuestionarios/Cuestionarios';
import Video from './components/Video/Video';
import Preguntas from './components/Preguntas/Preguntas';
import Insignias from './components/Insignias/Insignias';
import InsigniasTotales from './components/InsigniasTotales/InsigniasTotales';
import PerfilNav from './components/PerfilNav/PerfilNav';
import Perfil from './components/Perfil/Perfil';
import Calendario from './components/Calendario/Calendario';
import ListaWork from './components/ListaWork/ListaWork';
import Workouts from './components/Workouts/Workouts';
import WorkoutsB from './components/WorkoutsB/WorkoutsB';
import Semana from './components/Semana/Semana';
import Instructor from './components/Instructor/Instructor';
import GuardadosNav from './components/GuardadosNav/GuardadosNav';
import Lista from './components/Lista/Lista';
import Mensajes from './components/Mensajes/Mensajes';
import Bienvenida from './components/Bienvenida/Bienvenida';
import Testimonios from './components/Testimonios/Testimonios';
import Live from './components/Live/Live';
import useToken from './useToken';
import { requestPermission, unsubscribe, onMessageListener } from './push-notification';
import share from './img/share.png';
import './estilos.css';

function App() {
  const [modo, setModo] = useState(localStorage.getItem('modo') || "oscuro");
  const [perfil, setPerfil] = useState({});
  const [nuevos, setNuevos] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [instalar, setInstalar] = useState(false);
  const [installPromptEvent, setInstallPromptEvent] = useState(null);
  const [iOSinstall, setIOSinstall] = useState(false);

  onMessageListener()?.then(payload => {
    setShow(true);
    setNotification(payload)
		setTimeout(()=>{
			setShow(false);
		},5000)
  }).catch(err => console.log('failed: ', err));


  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  useEffect(() => {
    setIOSinstall(iOS && !navigator.standalone);
    setTimeout(()=>{
      setIOSinstall(false);
    }, 15000)
    window.addEventListener('beforeinstallprompt', (e) => {
      // Evita que Chrome muestre el mini-infobar
      e.preventDefault();
      // Guarda el evento para que pueda ser llamado más tarde
      setInstallPromptEvent(e);
      setInstalar(true);
    });
    window.addEventListener('appinstalled', () => {
      // La aplicación fue instalada
      setInstalar(false);
    });
  }, []);

  useEffect(()=>{
    localStorage.setItem("modo", modo);
  }, [modo])
  const [login, setLogin] = useState(false);
  const {token, setToken} = useToken();
  useEffect(()=>{
    if(token!="" && !iOS){
      requestPermission();
    }
  },[token])

  const handleInstallPWA = () => {
    if (!installPromptEvent) return;
    // Muestra el mensaje de instalación
    installPromptEvent.prompt();
    // Espera a que el usuario responda a la pregunta
    installPromptEvent.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('El usuario aceptó la instalación');
      } else {
        console.log('El usuario rechazó la instalación');
      }
      // Borra el evento guardado
      setInstallPromptEvent(null);
    });
  };

  return (
    <LoginContext.Provider value={{setLogin : setLogin, setToken : setToken, token : token}}>
      <BrowserRouter>
      <div className={"todo "+modo}>
        {iOSinstall &&
          <div className="iOSinstall" onClick={()=>{
            setIOSinstall(false);
          }}>
            <span>
              <b>Instala la app:</b> Presiona <img src={share}/> y luego selecciona "Agregar a inicio"
            </span>
            <div className="triangulo"></div>
          </div>
        }
        {show && 
          <Notificacion notificacion={notification} setShow={setShow}></Notificacion>
        }
        <div className="contenedor">
          {instalar &&
            <div className='instalar'>
              <span onClick={handleInstallPWA}>Instala la app</span>
            </div>
          }
          <Routes>
            <Route path="/" element={<UserBar />}>
              <Route path="/" element={<Inicio setNuevos={setNuevos} />}>
              </Route>
              <Route path="/lives">
              </Route>
              <Route path="/lives/:id" element={<Live/>}>
              </Route>
              <Route path="/cursos">
                <Route path="/cursos/"  element={<Cursos modo={modo}/>}>
                </Route>
                <Route path="/cursos/:id" element={<Curso modo={modo} />}>
                  <Route path="/cursos/:id" element={<Secciones key={"secciones1"}/>} >
                  </Route>
                  <Route path="/cursos/:id/secciones" element={<Secciones key={"secciones"}/>} >
                  </Route>
                  <Route path="/cursos/:id/secciones/:idSeccion" element={<Secciones key={"secciones"}/>} >
                  </Route>
                  <Route path="/cursos/:id/inscribir" element={<Inscribir key={"inscribe"}/>} >
                  </Route>
                  <Route path="/cursos/:id/insignias" element={<Insignias/>} >
                  </Route>
                </Route>
              </Route>

              <Route path="/cursos/categoria/:categoria" element={<Cursos modo={modo}/>}></Route>

              <Route path="/quizz/" element={<Cuestionarios/>}>
              </Route>
              <Route path="/preguntas/:id" element={<Preguntas tipo='seccion'/>}>
              </Route>
              <Route path="/preguntas/cuestionario/:id" element={<Preguntas tipo='cuestionario'/>}>
              </Route>
              <Route path="/preguntas/video/:id" element={<Preguntas tipo='video'/>}>
              </Route>
              <Route path="/video/:id" element={<Video/>}>
              </Route>
              <Route path="/guardados" element={<GuardadosNav modo={modo} tipo='guardados'/>}>
                <Route path="/guardados" element={<Lista tipo='guardados' key='guardados'/>}>
                </Route>
                <Route path="/guardados/shorts" element={<Shorts tipo='guardados' key='guardados'/>}>
                </Route>
              </Route>
              <Route path="/conseguidas" element={<InsigniasTotales />}>
              </Route>
              <Route path="/favoritos" element={<GuardadosNav modo={modo} tipo='favoritos'/>}>
                <Route path="/favoritos" element={<Lista tipo='favoritos' key='favoritos'/>}>
                </Route>
                <Route path="/favoritos/shorts" element={<Shorts tipo='favoritos' key='favoritos'/>}>
                </Route>
              </Route>
              <Route path="/shorts" element={<Shorts setNuevos={setNuevos}/>}>
                <Route path="/shorts/:id" element={<Preview/>}>
                </Route>
              </Route>
              <Route path="/shorts/categoria/:categoria" element={<Shorts key='categorias'/>}>
                <Route path="/shorts/categoria/:categoria/:id" element={<Preview/>}>
                </Route>
              </Route>
              <Route path='/mensajes' element={<Mensajes/>}>
              </Route>
              <Route path='/bienvenida' element={<Bienvenida tipo="bienvenida"/>}>
              </Route>
              <Route path='/testimonios' element={<Testimonios tipo="testimonio"/>}>
              </Route>
            </Route>
            <Route path="/cuestionario/:id" element={<Cuestionario/>}>
            </Route>
            <Route path="/perfil" element={<PerfilNav modo={modo}/>}>
              <Route path="/perfil/" element={<Perfil setModo={setModo} modo={modo} perfil={perfil} setPerfil={setPerfil} notifica={requestPermission}/>}>
              </Route>
              <Route path="/perfil/workouts" element={<Calendario />}>
              </Route>
              <Route path="/perfil/workouts/lista" element={<ListaWork />}>
              </Route>
              <Route path="/perfil/workouts/:fecha" element={<Workouts />}>
              </Route>
              <Route path="/perfil/workouts/buscar" element={<WorkoutsB/>}>
              </Route>
              <Route path="/perfil/instructor" element={<Instructor />}>
              </Route>
            </Route>
            <Route path="/login" element={<Login modo={modo} />}>
            </Route>
            <Route path="/registro" element={<Registro />}></Route>
            <Route path="/restablecer" element={<Restablecer />}></Route>
            <Route path="/restablecer/:codigo" element={<Restablece />}></Route>
            <Route path="*" exact element={<UserBar/>} />
          </Routes>
        </div>
        <NavBar nuevos={nuevos} modo={modo}/>
      </div>
      </BrowserRouter>
    </LoginContext.Provider>
  );
}

export default App;
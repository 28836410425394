import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useParams, Outlet } from 'react-router-dom';
import Curso from '../Curso/Curso';
import './PerfilNav.css';
import perfilNav from '../../img/perperfil.png';
import perfilNavC from '../../img/perperfilred.png';
import semNav from '../../img/weeks.png';
import coach from '../../img/instructor.png';
import coachC from '../../img/instructorred.png';
import testiNav from '../../img/sinfoto.png';
import inscritosNav from '../../img/sinfoto.png';

const PerfilNav = (({modo})=>{
	const {id} = useParams();
	return(
		<>
		<nav className="navCurso navPerfil">
			<span>
				<NavLink to={'/perfil/'}>
					<img src={modo=="oscuro" ? perfilNav : perfilNavC}/>
					<span>Mi Perfil</span>
				</NavLink>
			</span>
			<span>
				<NavLink to={'/perfil/instructor'}>
					<img src={modo=="oscuro" ? coach : coachC}/>
					<span>Instructor</span>
				</NavLink>
			</span>
		</nav>
		<Outlet /> 
		</>
	);
})


export default PerfilNav;
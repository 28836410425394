import React, { useState, useEffect, useRef, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import { Link, useParams } from 'react-router-dom';
import VerFile from '../VerFile/VerFile';
import Alert from '../Alert/Alert';
import './Bienvenida.css';

import Edit from '../../img/edit.png';
import Guardar from '../../img/guardar.png';
import Cancelar from '../../img/cancelar.png';

const Bienvenida = (({tipo})=>{
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [multimedias, setMultimedias] = useState([]);
	const [buscar, setBuscar] = useState('');
	useEffect(()=>{
		getData();
	}, [])
	async function getData(){
		const data = await fetch("/cursos/clienteAPI/Bienvenida/getAll.php?tipo="+tipo+"&token="+token)
		.then(response=> response.json())
		.then(json=>{
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			return json;
		});
		setMultimedias(data || {});
	}
	return(
		<>
			{/*<div className='buscador navPer'>
				<input 
				placeholder='Buscar en Videos'
				onChange={(e)=>{
					setBuscar(e.target.value);
				}}/>
			</div>*/}
			<div className="titBienvenida">{tipo== "bienvenida" ? "¡Bienvenido a Savage Challenges!" : "Gente que como tú, quiso cambiar su vida"}</div>
			<div className="titBajo">{tipo== "bienvenida" ? "Ahora eres parte de nuestra familia" : "Conoce las historias de nuestra familia"}</div>
			<div className='contMultimedias multiCliente'>
			{
			multimedias.map((multimedia, i)=>{
				multimedia.orden = i+1;

				if(buscar=='' || multimedia.nombre.toLowerCase().includes(buscar.toLowerCase()))
				return (<Multimedia multimedia={multimedia} key={multimedia.id_multimedia} tipo={tipo} setMultimedias={setMultimedias} multimedias={multimedias}/>);
			})
			}
			{multimedias.length == 0 &&
				<div className="lvacio">Aún no han agregado ningún video.</div>
			}
			</div>
		</>
	);
})

const Multimedia = (({multimedia, setNuevo, setMultimedias, multimedias, tipo})=>{
	const {id} = useParams();
	const {setLogin, setToken, token} = useContext(LoginContext);
	const [editar, setEditar] = useState(multimedia.id_multimedia==0);
	const [imagen, setImagen] = useState({id_archivo : multimedia.id_archivo , ruta : multimedia.ruta});
	const [alert, setAlert] = useState({ver:false, mensaje:"", funcion: null, confirm:true});
	const [cargando, setCargando] = useState(false);

	useEffect(()=>{
		if(alert.ver){
			document.body.addEventListener("keydown", cerrar)
		}
		else{
			document.body.removeEventListener("keydown", cerrar)
		}
	}, [alert.ver])

	function cerrar(e){
		if(e.code=="Escape"){
			if(document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA"){
				setAlert({...alert, ver:false});
			}
		}
	}
	return(<>
		{alert.ver &&
			<Alert alert={alert} setAlert={setAlert}/>
		}
		<div className='infoMultimedia'> 
			<div className='descMultimedia'>
				<VerFile  imagen={imagen} tipo={"multi"}/>
				<div className='datosMultimedia divsEstilo'>
					<div>
						{editar &&
							<input defaultValue={multimedia.nombre} onChange={(e)=>{
								multimedia.nombre = e.currentTarget.value
							}}/>
						}
						{!editar &&
							<span>{multimedia.nombre}</span>

						}
					</div>
					<div className='descripcion'>
						{editar &&
							<textarea defaultValue={multimedia.descripcion} onChange={(e)=>{
								multimedia.descripcion = e.currentTarget.value
							}}></textarea>
						}
						{!editar &&
							<span>{multimedia.descripcion}</span>

						}
					</div>
				</div>
			</div>
		</div>
	</>);
});

export default Bienvenida;
import React, { useState, useEffect, useContext } from 'react';
import LoginContext from '../../components/Login/LoginContext';
import useToken from '../../useToken';
import {useNavigate, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../img/logosavage.png';
import logoC from '../../img/logosavagered.png';
import './Login.css';

async function loginUser(credentials) {
 return fetch('/cursos/clienteAPI/login.php', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}

export default function Login({modo}) {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState();
  const [intento, setIntento] = useState(0);
  const [password, setPassword] = useState();
  const [showError, setShowError] = useState(false);
  const {setLogin, setToken, token} = useContext(LoginContext);

  function handleCredentialResponse(response) {
    fetch("/cursos/clienteAPI/logingoogle.php", {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json'
       },
       body: JSON.stringify(response)
     })
    .then(response=>response.json())
    .then(json=>{
        if(json["token"]){
          setToken(json);
          setLogin(false);
          if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
          }
          else{
              navigate('/', { replace: true });
          }
          //window.location.reload()
        }
    })
    .catch((error)=>{
      console.log(error)
    })
  }
  
  useEffect(()=>{
    const google = window.google;
    if(google){

     google.accounts.id.initialize({
        client_id: "416748913463-ul77c9tue1ilbk7tuju921ajg4q2elb0.apps.googleusercontent.com",
        callback: handleCredentialResponse
      });
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }  // customization attributes
      );
      google.accounts.id.prompt(); // also display the One Tap dialog
      
    }
    else{
      setTimeout(function(){
        if(intento < 5)
          setIntento(intento+1);
      },1000)
    }
  }, [intento])

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      usuario,
      password
    });
    if(!token.token){
      setShowError(true);
      setTimeout(function(){
        setShowError(false)
      },3000)
    }
    else{
      setToken(token);
      setLogin(false);
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      }
      else{
          navigate('/', { replace: true });
      }
    }
  }

  return(
    <>
    <div className="login">
      <div>
        <div className="encaLog">
          <h1>Inicia Sesión</h1>
          <img src={modo=="oscuro" ? logo : logoC}/>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="inputSesion">
            <label>
              <input type="text" onChange={e => setUsuario(e.target.value)} placeholder='Correo electrónico o Usuario'/>
            </label>
            <label>
              <input type="password" onChange={e => setPassword(e.target.value)} placeholder='Contraseña'/>
            </label>
          </div>
          <div className="bIniciar">
            <div className='botones'>
              <div className='sigLetra' onClick={()=>{
                navigate('/registro', {replace:true})
              }}>Crear Cuenta</div>
            </div>
            <div><button type="submit" className="botbot">Iniciar Sesión</button></div>
          </div>
          <div className="moverG">
            <div id='buttonDiv' className="googleDiv">
          </div>
          </div>
        </form>
        {showError &&
        <div className='logError'>
           <div>Los datos ingresados no son correctos, comprueba los datos e intentalo de nuevo.</div>
        </div>
        }
        <div className='forgot'>
          <Link to='/restablecer'>Olvidé mi Contraseña</Link>
        </div>
      </div>
    </div>
    </>
  )
}

import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useParams, Outlet } from 'react-router-dom';
import './GuardadosNav.css';
import shorts from '../../img/shorts.png';
import shortsC from '../../img/shortsred.png';
import videoc from '../../img/videoc.png';
import videocC from '../../img/videocred.png';

const GuardadosNav = (({tipo, modo})=>{
	const {id} = useParams();
	return(
		<>
		<nav className="navCurso">
			<span>
				<NavLink to={'/'+tipo+'/'}>
					<img src={modo=="oscuro" ? videoc : videocC}/>
					<span>Videos/Cuestionarios</span>
				</NavLink>
			</span>
			<span>
				<NavLink to={'/'+tipo+'/shorts'}>  
					<img src={modo=="oscuro" ? shorts : shortsC}/>
					<span>Shorts</span>
				</NavLink>
			</span>
		</nav>
		<Outlet /> 
		</>
	);
})


export default GuardadosNav;